import { Box, Theme } from '@mui/material';
import React from 'react';
import { CraftGql_Body_InformationBlock_BlockType } from '../../graphql-types';
import { IconWave } from '../assets/icons/how-it-works/wave';
import { ImageMoreListings } from '../assets/images';
import { LL_BACKGROUND_COLOR } from '../colors';
import { InformationBlock } from '../components/information-block';
import { Width80Box } from '../components/primitives/Width80Box';

export const InformationSection = (props: {
  theme: Theme;
  item: CraftGql_Body_InformationBlock_BlockType;
}) => {
  const { item, theme } = props;

  return (
    <>
      <IconWave width="100%" height="auto" marginBottom="-5px" />
      <Box
        sx={{
          background: LL_BACKGROUND_COLOR,
          padding: '40px 0 110px',
          [theme.breakpoints.down('md')]: {
            padding: '40px 20px'
          }
        }}
      >
        <Width80Box>
          {item?.informationRowUpdated?.map((row, index) => (
            <InformationBlock
              key={row.id}
              heading={row.infoTitle || 'Win more listings and sell properties faster'}
              description={
                row.infoDescription ||
                'Real estate agents across Australia are using Listing Loop as a pre-market and off-market vendor sales strategy to win more listings and sell properties faster. Our industry first no sale, no charge success-based advertising model allows you to market your vendor’s property right away with no upfront fees.'
              }
              link={{ label: 'Get started', href: row.infoTitle }}
              imageAlign={index % 2 === 0 ? 'right' : 'left'}
              image={
                <img
                  src={row.infoImage[0]?.url || ImageMoreListings}
                  height="auto"
                  width="100%"
                  style={{ objectFit: 'cover' }}
                />
              }
              firstBlock={index === 0}
            />
          ))}
        </Width80Box>
      </Box>
    </>
  );
};
