import { Button, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { TABS_COLOR } from '../colors';
import { UserType } from '../interfaces/user';

export interface AgentBuyerTabsProps {
  activeTab: UserType;
  handleActiveTab: (userType: UserType) => void;
}

export default function AgentBuyerTabs(props: AgentBuyerTabsProps) {
  const theme = useTheme();
  const { activeTab, handleActiveTab } = props;

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Button
        onClick={() => handleActiveTab(UserType.buyer)}
        sx={{
          border: activeTab === UserType.buyer && `2px solid ${theme.palette.primary.main}`,
          borderRadius: '50px',
          color: activeTab === UserType.buyer ? theme.palette.secondary.main : TABS_COLOR,
          textTransform: 'none',
          padding: '15px 35px'
        }}
        variant={'text'}
      >
        {'For Buyers'}
      </Button>
      <Button
        onClick={() => handleActiveTab(UserType.agent)}
        sx={{
          border: activeTab === UserType.agent && '2px solid ' + theme.palette.primary.main,
          borderRadius: '50px',
          color: activeTab === UserType.agent ? theme.palette.secondary.main : TABS_COLOR,
          marginLeft: '16px',
          textTransform: 'none',
          padding: '15px 35px',
          [theme.breakpoints.down('sm')]: {
            padding: '15px 20px'
          }
        }}
        variant={'text'}
      >
        {'For Agents'}
      </Button>
    </Box>
  );
}
