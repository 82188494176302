import React from 'react';
import { Box, Typography, Theme, Grid } from '@mui/material';
import { CraftGql_Body_QuotesBlock_BlockType } from '../../graphql-types';
import { PrimaryButton } from '../components/primitives/primary-button';
import { IconDiscoverProperty } from '../assets/icons';
import { Width80Box } from '../components/primitives/Width80Box';
import { IconLogoWithStar } from '../assets/icons/about-us/listing-loop-logo-with-star';

export const QuoteSection = (props: {
  theme: Theme;
  item: CraftGql_Body_QuotesBlock_BlockType;
}) => {
  const { item, theme } = props;
  return (
    <Box paddingTop={{ xs: '40px', md: '90px', lg: '90px' }}>
        <Width80Box px="20px">
          <Box maxWidth="870px" mx="auto" width="100%" textAlign="center">
            <IconLogoWithStar />
            <Typography
              color="primary.main"
              paddingTop="30px"
              variant="h3"
              fontWeight="500"
              mb="30px"
              mx="12px"
              fontFamily="Tropiline"
              sx={{ [theme.breakpoints.down('md')]: { textAlign: 'center' } }}
            >
              {item.ceoMessage}
            </Typography>
            <Typography marginTop="30px" fontWeight={500}>
              {item.bodyText}
            </Typography>
            <Typography>{item.textSubtitle}</Typography>
          </Box>
        </Width80Box>
      </Box>
  );
};

