import React from 'react';
import { IconSvg } from '../icon-props';

export const IconLogoWithStar = (props: React.CSSProperties) => {
  return (
    <IconSvg
      width={props.width || '128.521'}
      height={props.height || '102.629'}
      viewBox="0 0 128.521 102.629"
      fill={props.fill}
      style={{ ...props }}
    >
      <g id="Group_731" data-name="Group 731" transform="translate(-6523.735 -3483.351)">
        <g id="Group_187" data-name="Group 187">
          <path
            id="Path_417"
            data-name="Path 417"
            d="M6569.1,3585.98c-20.947,0-34.44-16.852-34.6-17.049-.028-.036-.055-.072-.08-.11-13.119-19.439-11.8-34.5-8.389-43.709,4.657-12.56,16.291-22.481,31.123-26.539,24.827-6.8,45.011,3.169,54,26.649,4.26,11.129,3.33,24.13-2.553,35.672a45.458,45.458,0,0,1-28.934,23.663A40.6,40.6,0,0,1,6569.1,3585.98Zm-31.57-19.35c.891,1.092,17.3,20.673,41.155,14.257a41.7,41.7,0,0,0,26.535-21.717c5.316-10.431,6.209-22.613,2.39-32.59-8.217-21.466-26.7-30.564-49.449-24.343-13.653,3.736-24.331,12.781-28.562,24.195C6525.158,3538.426,6527.9,3552.324,6537.535,3566.63Z"
            fill="#151f35"
          />
          <path
            id="Path_418"
            data-name="Path 418"
            d="M6548.939,3551.377a2.935,2.935,0,0,1-1.847-.661,3.172,3.172,0,0,1-1.153-2.353l-.973-25.583a3.149,3.149,0,0,1,1.723-2.962l23.426-11.157a2.887,2.887,0,0,1,2.974.272,3.181,3.181,0,0,1,1.287,2.544l.133,13.681a3.118,3.118,0,0,1-1.886,2.942l-11.579,4.833c.429,13.227-.258,14.165-1.922,15.1-.261.145-1.052.589-9.308,3.211A2.859,2.859,0,0,1,6548.939,3551.377Zm-.275-3.756h0Zm-.65-25.32,1.686,24.989c4.119-1.318,6.556-2.158,7.358-2.484.228-.879,1.116-2.986.785-12.257l-.047-1.314,12.91-6.452-.119-12.139Zm23.759-10.222-.022.011Z"
            fill="#151f35"
          />
          <path
            id="Path_419"
            data-name="Path 419"
            d="M6562.4,3572.41a2.939,2.939,0,0,1-1.883-.692,3.175,3.175,0,0,1-1.119-2.443v-12.94a3.1,3.1,0,0,1,2.136-3l12.854-4.044-.78-12.985a3.155,3.155,0,0,1,1.811-3.077l9.956-4.461a2.912,2.912,0,0,1,2.932.328,3.183,3.183,0,0,1,1.255,2.494l.761,29.244a3.146,3.146,0,0,1-2.106,3.067l-24.9,8.362A2.882,2.882,0,0,1,6562.4,3572.41Zm.8-15.616v11.5l24.137-7.511-1.255-29.28-9.83,4.183,1.255,16.1Zm25.81,4.825-.014.005Zm-2.075-29.387,0,0Zm-1.169-.561v0Z"
            fill="#151f35"
          />
        </g>
        <path
          id="Path_420"
          data-name="Path 420"
          d="M6634.707,3484.321c-.777,3.752-1.538,7.493-2.3,11.248a3.164,3.164,0,0,0,.344,2.569,2.242,2.242,0,0,0,2.667.588c.937-.316.886-.75.824-1.283a2.541,2.541,0,0,1-.016-.7c.19-1.288.461-2.563.732-3.839.081-.383.162-.767.241-1.15.333-1.623.63-2.883.954-4.259h0c.164-.7.336-1.423.522-2.241a1.556,1.556,0,0,0-.132-.9c-.026-.06-.047-.123-.069-.187a1,1,0,0,0-.35-.557l-.029-.021h0a.468.468,0,0,0-.124-.071,4.67,4.67,0,0,0-2.287-.059A1.176,1.176,0,0,0,6634.707,3484.321Zm-5.9,30.582q-.986,5.112-1.975,10.253a2.683,2.683,0,0,0,.4,2.312,2.412,2.412,0,0,0,2.682.493c.932-.3.869-.684.79-1.167a2.163,2.163,0,0,1-.036-.641c.152-1.176.385-2.339.618-3.5h0c.07-.35.14-.7.207-1.049.272-1.422.527-2.535.8-3.731.156-.682.318-1.391.493-2.2a1.325,1.325,0,0,0-.16-.8c-.02-.045-.038-.094-.056-.144h0a.864.864,0,0,0-.375-.517.6.6,0,0,0-.155-.079,5.186,5.186,0,0,0-2.279-.017C6629.127,3514.268,6628.87,3514.592,6628.811,3514.9Zm20.278-5.935c-.884.063-1.769.16-2.654.257a34.675,34.675,0,0,1-5.192.309,1.551,1.551,0,0,1-.68-.2l-.085-.044h0c-.375-.191-.758-.387-.775-1.669-.021-1.029.119-1.033.392-1.04a.934.934,0,0,0,.355-.06,4.339,4.339,0,0,1,2.046-.318c.207.009.419.017.657.008,1.235-.034,1.845-.014,2.639.012h0c.427.014.908.03,1.568.04a36.355,36.355,0,0,1,4.425.278c.595.088.5.472.38,1a5.239,5.239,0,0,0-.116.6c-.079.725-.352.782-.574.778A18.754,18.754,0,0,0,6649.089,3508.968Zm-32.62-4.012c.869.26,1.737.519,2.588.813a18.28,18.28,0,0,1,2.178.892c.209.087.478.154.837-.489a5.251,5.251,0,0,1,.344-.5c.325-.436.562-.753.041-1.067a39.553,39.553,0,0,0-3.965-1.977c-.646-.282-1.1-.491-1.5-.678-.686-.316-1.235-.569-2.351-1.005-.2-.081-.383-.167-.557-.25a4.407,4.407,0,0,0-2.041-.523.945.945,0,0,1-.345-.091c-.256-.107-.388-.162-.776.8-.473,1.185-.193,1.519.075,1.839.02.024.041.048.06.072a1.778,1.778,0,0,0,.56.445A34.29,34.29,0,0,0,6616.469,3504.956Z"
          fill="#00a6dd"
          fill-rule="evenodd"
        />
      </g>
    </IconSvg>
  );
};
