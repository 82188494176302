import React, { useEffect, useRef } from 'react';
import { Box, Theme } from '@mui/material';
import { CraftGql_Body_RibbonSection_BlockType } from '../../graphql-types';
import { CurveWithArrow } from '../assets/icons';
import { Pattern2 } from '../assets/icons/pattern';
import { Absolute } from '../components/primitives/absolute';
import { PrimaryButton } from '../components/primitives/primary-button';
import { ImageSearchProperty } from '../assets/images';
import { LIGHT_VIOLET_COLOR } from '../colors';
import { Link } from 'gatsby';
import { linkStyle } from '../constants';

const RibbonSection = (props: {
  theme: Theme;
  item: CraftGql_Body_RibbonSection_BlockType;
  isMobile: boolean;
}) => {
  const { item, isMobile, theme } = props;
  const { textTitle, primaryButton } = item;
  let textPathRef = useRef(null);
  let textContainerRef = useRef(null);
  let pathRef = useRef(null);
  const floatingText = textTitle || `Stop searching for your perfect property. Let it find you.`;
  const buttonLabel = (primaryButton && primaryButton[0]?.buttonLabel) || "Let's get started";
  const buttonLink = (primaryButton && primaryButton[0]?.buttonDestinationUrl) || '/';

  useEffect(() => {
    // repeatOften();
  }, []);
  return (
    <>
      <Box
        marginX="auto"
        sx={{
          overflow: 'hidden',
          [theme.breakpoints.down(1025)]: {}
        }}
      >
        <Box
          position="relative"
          style={{
            backgroundImage: `url(${ImageSearchProperty})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            width: '110%',
            paddingTop: '20vh',
            paddingBottom: '20vh',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Absolute left="40%" top="10px" width="12%">
            <Pattern2 width="100%" height="auto" />
          </Absolute>

          <div
            style={{ position: 'relative', marginLeft: '-5%', marginRight: '-5%', width: '100%' }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              // width="1730.43"
              width="100%"
              height="auto"
              viewBox="0 0 1730.43 347.147"
            >
              <path
                id="Path_1576"
                d="M1867.29,4697.705s180.33-210.055,443.889-212.037,324.99,206.092,725.283,259.6,493.43-196.183,493.43-196.183"
                transform="translate(-1836.94 -4445.655)"
                fill="none"
                stroke="#d9dfef"
                strokeMiterlimit="10"
                strokeWidth="80"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              // width="1730.43"
              style={{
                left: '3px',
                position: 'absolute',
                top: isMobile ? '1px' : '7px'
              }}
              height="auto"
              viewBox="0 0 1730.43 347.147"
            >
              <path
                id="LineSVG"
                ref={pathRef}
                d="M1867.29,4697.705s180.33-210.055,443.889-212.037,324.99,206.092,725.283,259.6,493.43-196.183,493.43-196.183"
                transform="translate(-1836.94 -4445.655)"
                fill="none"
                // stroke="#000"
                strokeMiterlimit="10"
                strokeWidth="80"
              />
              <text id="text-container" ref={textContainerRef}>
                <textPath
                  href="#LineSVG"
                  id="text-path"
                  fontSize="32px"
                  startOffset="50%"
                  style={{
                    fontFamily: 'Tropiline',
                    fontSize: isMobile ? '50px' : '32px'
                  }}
                  ref={textPathRef}
                >
                  <animate
                    attributeName="startOffset"
                    dur="15s"
                    from="100%"
                    to="-100%"
                    repeatCount="indefinite"
                  />

                  {floatingText}
                </textPath>
              </text>
            </svg>
            <Absolute top={{ xs: '', md: '23%' }} left="25%" width="6.25%">
              <Box display={{ xs: 'none', md: 'inline-block' }}>
                <CurveWithArrow color={LIGHT_VIOLET_COLOR} height="auto" width="100%" />
              </Box>
              <Link to={buttonLink} style={linkStyle}>
                <PrimaryButton
                  sx={{
                    marginTop: '8px',
                    textTransform: 'none',
                    whiteSpace: 'nowrap'
                  }}
                  variant="contained"
                >
                  {buttonLabel}
                </PrimaryButton>
              </Link>
            </Absolute>
          </div>
        </Box>
      </Box>
    </>
  );
};

export default RibbonSection;
