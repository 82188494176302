import { Box, Theme } from '@mui/material';
import React from 'react';
import { CraftGql_Body_DownloadApp_BlockType } from '../../graphql-types';
import { SecretPropertyMarket } from '../components';
import { Width80Box } from '../components/primitives/Width80Box';

const DownloadAppSection = (props: { item: CraftGql_Body_DownloadApp_BlockType; theme: Theme }) => {
  const { item, theme } = props;
  return (
    <>
      <Width80Box
        sx={{
          paddingTop: '150px',
          [theme.breakpoints.down(1025)]: {
            paddingX: '20px',
            paddingTop: '30px'
          }
        }}
      >
        <SecretPropertyMarket content={item} />
      </Width80Box>
    </>
  );
};
export default DownloadAppSection;
