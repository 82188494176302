import { Box, Theme, Typography } from '@mui/material';
import React, { useState } from 'react';
import { CraftGql_Body_Faqs_BlockType } from '../../graphql-types';
import { Pattern1 } from '../assets/icons/pattern';
import AgentBuyerTabs from '../components/agent-buyer-tabs';
import { FaqAccordion } from '../components/faqs/FaqAccordion';
import { Width80Box } from '../components/primitives/Width80Box';
import { UserType } from '../interfaces/user';
import PricingSection from './pricing-section';

export const FaqsSection = (props: { theme: Theme; item: CraftGql_Body_Faqs_BlockType }) => {
  const { theme, item } = props;
  const buyerFaqs = [];
  const AgentFaqs = [];
  const faqs = item.faqs.forEach((faq) => {
    if (faq.faqFor == 'buyer') {
      buyerFaqs.push({
        question: faq.faqQuestion,
        answer: faq.faqAnswer
      });
    } else {
      AgentFaqs.push({
        question: faq.faqQuestion,
        answer: faq.faqAnswer
      });
    }
  });
  const [activeTab, setActiveTab] = useState<UserType>(UserType.buyer);

  const handleActiveTab = (tab: UserType) => {
    setActiveTab(tab);
  };

  return (
    <Width80Box
      sx={{
        paddingY: '100px',
        paddingTop: '200px',
        paddingX: '20px',
        maxWidth: '952px',
        [theme.breakpoints.down('md')]: {
          paddingTop: '100px'
        }
      }}
    >
      <Box>
        <Box paddingRight="7.5%" textAlign="right" width="100%">
          <Pattern1 color={'secondary'} width="5.5%" height="auto" />
        </Box>
        <Typography
          color="primary.main"
          fontFamily="Tropiline"
          textAlign="center"
          variant="h2"
          py="10px"
          position="relative"
        >
          {item.textTitle || 'Frequently Asked Questions'}
        </Typography>
        <Box paddingLeft="10%" textAlign="left" width="100%">
          <Pattern1 color={'secondary'} width="5.5%" height="auto" />
        </Box>
      </Box>
      <Box sx={{ paddingY: '50px', [theme.breakpoints.down('md')]: { paddingY: '30px' } }}>
        <AgentBuyerTabs activeTab={activeTab} handleActiveTab={handleActiveTab} />
      </Box>
      {/* Change faqs json if active tab change */}
      <FaqAccordion faqs={activeTab == UserType.buyer ? buyerFaqs : AgentFaqs} />
    </Width80Box>
  );
};

export default PricingSection;
