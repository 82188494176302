import React from 'react';
import { Box, Typography, Theme, Grid } from '@mui/material';
import { DARK_NAVY_COLOR } from '../colors';
import { Width80Box } from '../components/primitives/Width80Box';
import { IconBlueMap } from '../assets/icons/about-us/blue-map';

export const AustraliasFastestSection = (props: {
  theme: Theme;
  item: CraftGQL_Body_AustraliasFastestGrowing_BlockType;
}) => {
  const { item, theme } = props;
  return (
<Box
        paddingY={{ xs: '40px', sm: '40px', md: '40px', lg: '110px' }}
        px={'20px'}
        bgcolor={DARK_NAVY_COLOR}
      >      
              <style>{`
        .illustrationBlockText strong {
          color: ${theme.palette.primary.main};
          font-family: 'Tropiline';
        }
        `}</style>
    <Width80Box>
          <Grid
            container
            spacing={{ xs: 0, md: 2 }}
            alignItems={'center'}
            justifyContent="center"
            sx={{ flexGrow: 1, marginTop: '44px' }}
          >
            <Grid item xs={7} md={5}>
              
              {item.imageElement[0] ? (
              <img
                src={item.imageElement[0].url}
                style={{ objectFit: 'cover', objectPosition: 'center', maxWidth: '433px', width:'100%' }}
              />
            ) : (
                <IconBlueMap height="auto" maxWidth="433px" width="100%" />
            )}
            </Grid>
            <Grid item xs={12} md={7}>
            <Typography
              color="white"
              fontWeight={500}
              textAlign={{ xs: 'center', md: 'left', lg: 'left' }}
              variant="h3"
              className="illustrationBlockText"
              dangerouslySetInnerHTML={{ __html: item.richTextContent }}
            ></Typography>
            </Grid>
          </Grid>
        </Width80Box>
      </Box>
  );
};


