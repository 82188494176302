import { Box, Theme, Typography } from '@mui/material';
import React from 'react';
import { Absolute } from '../primitives/absolute';
import { FlexBox } from '../primitives/flex';
import { PrimaryButton } from '../primitives/primary-button';

export interface PriceCardProps {
  cardColor: string;
  priceTitle: string;
  price: number;
  description: string;
  buttonLabel: string;
  onClick: () => void;
  theme: Theme;
  populor?: boolean;
  popularText?: string;
}

export const PriceCard = (props: PriceCardProps) => {
  const {
    cardColor,
    theme,
    price,
    priceTitle,
    description,
    buttonLabel,
    populor,
    popularText,
    onClick
  } = props;
  return (
    <Box
      sx={{
        width: 'auto',
        height: '100%',
        borderRadius: '0px 0px 27px 27px',
        border: `2px solid ${cardColor}`,
        borderTop: `8px solid ${cardColor}`
      }}
    >
      <FlexBox justifyContent="flex-end" height="46px">
        {populor && (
          <Box bgcolor={cardColor} padding="7px 20px 15px 20px">
            <Typography color="white">{popularText || 'Most Populor'}</Typography>
          </Box>
        )}
      </FlexBox>

      <FlexBox
        flexDirection="column"
        justifyContent="center"
        sx={{
          padding: '40px'
        }}
      >
        <Typography textAlign="center" variant="h3" mb="30px">
          {priceTitle}
        </Typography>

        <Box textAlign="center" mb="30px">
          <Typography fontSize={{ xs: '80px', md: '130px' }} component="span" position="relative">
            <Typography
              fontSize={{ xs: '30px', md: '40px' }}
              sx={{
                position: 'absolute',
                top: '20px',
                left: '-25px',
                [theme.breakpoints.down('md')]: { top: '10px' }
              }}
              component="span"
            >
              $
            </Typography>
            {price}
          </Typography>
          <Typography component="span">/mo</Typography>
        </Box>

        <Typography textAlign="center" px="20px" mb="50px" minHeight="220px">
          {description}
        </Typography>

        <PrimaryButton
          sx={{
            background: cardColor,
            boxShadow: 'none',
            '&:hover': { background: cardColor, opacity: '0.9' }
          }}
          variant="contained"
          fullWidth
          onClick={onClick}
        >
          {buttonLabel}
        </PrimaryButton>
      </FlexBox>
    </Box>
  );
};
