import React from 'react';
import { Box, useTheme } from '@mui/material';
import { IconVideoPlay } from '../assets/icons/video-play';

export default function VideoPlayIcon() {
  const theme = useTheme();
  return (
    <Box
      sx={{
        height: '70px',
        width: '70px',
        [theme.breakpoints.down(400)]: { height: '55px', width: '55px' }
      }}
    >
      <IconVideoPlay height="100%" width="100%" />
    </Box>
  );
}
