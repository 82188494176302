import {
  Card,
  CardContent,
  CardMedia,
  Chip,
  Grid,
  Theme,
  Typography,
  useTheme
} from '@mui/material';
import { Link } from 'gatsby';
import React from 'react';
import { SxProps } from '@mui/system';
import { CAPTIONS_TEXT_COLOR, LIGHT_VIOLET_COLOR } from '../colors';
import { linkStyle } from '../constants';
import { formatDateTimeWithMonthName } from '../utils/formatters';

interface NewsCardProps {
  customCardStyle?: SxProps;
  date: number;
  filters?: string[] | null;
  imageLink: string;
  title: string;
  slug: string;
  variant?: 'big' | 'small';
}

export const NewsCard = (props: NewsCardProps) => {
  const { customCardStyle, date, filters, imageLink, title, variant, slug } = props;
  const theme: Theme = useTheme();
  console.log({ date });
  if (variant === 'big') {
    return (
      <>
        <Grid alignItems="center" container>
          <Grid item xs={6} sm={7}>
            <Link style={linkStyle} to={`/news/${slug}`}>
              <CardMedia
                alt="News Image"
                component="img"
                image={imageLink}
                sx={{
                  borderRadius: '24px 24px 24px 0',
                  maxHeight: '460px',
                  maxWidth: '830px'
                }}
                width="100%"
              />
            </Link>
          </Grid>
          <Grid item marginLeft="40px" xs={6} sm={4}>
            <Typography color={CAPTIONS_TEXT_COLOR} marginTop="18px" variant="body2">
              {formatDateTimeWithMonthName(date)}
            </Typography>
            <Typography color="secondary.main" fontWeight={500} marginTop="10px" variant="h3">
              <Link style={linkStyle} to={`/news/${slug}`}>
                {title}
              </Link>
            </Typography>
            {filters &&
              filters.map((item) => (
                <Chip
                  label={item}
                  sx={{
                    background: LIGHT_VIOLET_COLOR,
                    color: (theme) => theme.palette.secondary.main,
                    fontSize: '16px',
                    marginRight: '8px',
                    marginTop: '8px',
                    textTransform: 'capitalize',
                    [theme.breakpoints.down('md')]: {
                      fontSize: '14px'
                    }
                  }}
                />
              ))}
          </Grid>
        </Grid>
      </>
    );
  }
  return (
    <Card
      sx={{
        borderRadius: 0,
        boxShadow: 'none',
        minWidth: '240px',
        maxWidth: '385px',
        ...customCardStyle
      }}
    >
      <Link style={linkStyle} to={`/news/${slug}`}>
        <CardMedia
          alt={slug}
          component="img"
          image={imageLink}
          sx={{
            borderRadius: '24px 24px 24px 0',
            height: '216px',
            [theme.breakpoints.down(500)]: {
              height: '180px'
            }
          }}
          width="100%"
        />
      </Link>

      <CardContent
        sx={{
          padding: 0
        }}
      >
        <Typography color={CAPTIONS_TEXT_COLOR} marginTop="18px" variant="body2">
          {formatDateTimeWithMonthName(date)}
        </Typography>
        <Typography color="secondary.main" fontWeight={500} marginTop="10px" variant="subtitle1">
          <Link style={linkStyle} to={`/news/${slug}`}>
            {title}
          </Link>
        </Typography>
        {filters &&
          filters.map((item) => (
            <Chip
              label={item}
              sx={{
                background: LIGHT_VIOLET_COLOR,
                color: (theme) => theme.palette.secondary.main,
                fontSize: '14px',
                marginRight: '8px',
                marginTop: '8px',
                textTransform: 'capitalize'
              }}
            />
          ))}
      </CardContent>
    </Card>
  );
};
