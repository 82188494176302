import { Box, Theme, Typography } from '@mui/material';
import React from 'react';
import { CraftGql_Body_Features_BlockType } from '../../graphql-types';
import { DiscoverPropertyCardSection } from '../components/discover-property-card-section';
import { Width80Box } from '../components/primitives/Width80Box';

const ListingLoopFeatures = (props: {
  item: CraftGql_Body_Features_BlockType;
  isMobile: boolean;
  theme: Theme;
}) => {
  const { item, isMobile, theme } = props;
  return (
    <Width80Box
      sx={{
        [theme.breakpoints.down(1025)]: {
          paddingX: '20px'
        },
        [theme.breakpoints.down('md')]: {
          marginTop: '60px'
        }
      }}
    >
      <Typography
        color="primary.main"
        fontFamily="Tropiline"
        textAlign="center"
        variant="h3"
        paddingX={!isMobile ? '15%' : 0}
      >
        {item.textTitle}
      </Typography>
      <DiscoverPropertyCardSection discoverProperties={item.featureCard} />
    </Width80Box>
  );
};

export default ListingLoopFeatures;
