import * as React from 'react';
import { styled } from '@mui/material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { useTheme, Theme } from '@mui/material';
import { DARK_TEXT_COLOR } from '../../colors';
import { Box } from '@mui/system';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  '&:before': {
    display: 'none'
  }
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  margin: 0,
  padding: '10px 0',
  backgroundColor: 'none'
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: '8px 0px 16px'
}));

interface AccordionIconProps {
  active?: boolean;
  theme: Theme;
}
const AccordionIcon = (props: AccordionIconProps) => {
  const { active, theme } = props;
  return (
    <Typography
      variant="h6"
      fontWeight={900}
      color={active ? theme.palette.primary.main : DARK_TEXT_COLOR}
    >
      +
    </Typography>
  );
};

export interface FaqAccordionProps {
  faqs: {
    question: string;
    answer: string;
  }[];
}

export const FaqAccordion = (props: FaqAccordionProps) => {
  
  const theme = useTheme();
  const { faqs } = props;
  const [expanded, setExpanded] = React.useState<string | false>('panel0');
  
  const [height, setHeight] = React.useState('')

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  React.useEffect(()=>{
    let box = document.querySelector('.box')
    let currentHeight = box.offsetHeight;
    console.log(currentHeight,'%%%')

    if(currentHeight > height){
      setHeight(currentHeight)
    }
    console.log(height,'%%%')
  }, [height])
 
  return (
    <Box>
      {faqs.map((faq, index) => {
        let panelName = `panel${index}`;
        return (
          <Accordion
            key={panelName}
            expanded={expanded === panelName}
            onChange={handleChange(panelName)}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
              expandIcon={<AccordionIcon theme={theme} active={expanded === panelName} />}
            >
              <Typography
                variant="h6"
                fontWeight={500}
                color={expanded === panelName ? theme.palette.primary.main : DARK_TEXT_COLOR}
              >
                {faq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails 
             className='box'
             sx={{height:{height}}}
            >
              <Typography 
             
              
              variant="body1">{faq.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
};
