import { Box, useTheme } from '@mui/system';
import React from 'react';
import { TeamMember, TeamMemberEntity } from '../about-us/listing-team';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

export interface ListingTeamCarouselProps {
  isMobile?: boolean;
  teamMembers: TeamMember[];
}

const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  900: { items: 3 },
  1024: { items: 4 },
  1600: { items: 5 }
};

export const ListingTeamCarousel = (props: ListingTeamCarouselProps) => {
  const { teamMembers } = props;
  const theme = useTheme();
  const slides = teamMembers.map((props, i) => {
    return (
      <div className="item" style={{ display: 'flex', justifyContent: 'center' }}>
        <TeamMemberEntity key={i} {...props} themeType="carousel" />
      </div>
    );
  });
  return (
    <Box
      sx={{
        paddingY: '50px',
        paddingBottom: '80px',
        [theme.breakpoints.down('md')]: { paddingY: '40px', paddingBottom: '40px' }
      }}
    >
      <AliceCarousel
        disableButtonsControls
        mouseTracking
        // items={slides}
        responsive={responsive}
        controlsStrategy="responsive"
        disableDotsControls
      >
        {teamMembers.map((props, i) => {
          return (
            <div className="item" style={{ display: 'flex', justifyContent: 'center' }}>
              <TeamMemberEntity key={i} {...props} themeType="carousel" />
            </div>
          );
        })}
      </AliceCarousel>
    </Box>
  );
  return (
    <Box
      padding="50px 0"
      sx={{
        display: 'flex',
        gridTemplateColumns: 'none',
        justifyContent: 'normal',
        rowGap: 0,
        columnGap: 0,
        marginY: '20px',
        overflowX: 'auto',
        '&::-webkit-scrollbar': {
          width: '0px',
          height: '0px'
        }
      }}
    >
      {teamMembers.map((props, i) => {
        return <TeamMemberEntity key={i} {...props} themeType="carousel" />;
      })}
    </Box>
  );
};
