import React,{useState} from 'react';
import { Box, Typography, Theme, Grid,useTheme } from '@mui/material';
import { CraftGQL_Body_FindAnAgent_BlockType } from '../../graphql-types';
import { PrimaryButton } from '../components/primitives/primary-button';
import { IconDiscoverProperty } from '../assets/icons';
import { Width80Box } from '../components/primitives/Width80Box';
import { IconLogoWithStar } from '../assets/icons/about-us/listing-loop-logo-with-star';
import { LL_BACKGROUND_COLOR } from '../colors';
import SearchInput from '../components/search-input';
import { IconSearchAgent } from '../assets/icons/search-agent';

export const FindAnAgentSection = (props: {
  theme: Theme;
  item: CraftGQL_Body_FindAnAgent_BlockType;
}) => {
  const { item } = props;
  const [searchText, setSearchText] = useState<string>('');
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const theme = useTheme();
  const handleChangeSearchText = (value: string) => {
    setSearchText(value);
  };
  
  return (
    <Box
    bgcolor={LL_BACKGROUND_COLOR}
    sx={{
      paddingY: '100px',
      paddingTop: '180px',
      paddingX: '20px',
      [theme.breakpoints.down('md')]: {
        paddingTop: '100px'
      }
    }}
  >
      
    <Width80Box>
      <Grid container flexDirection={{ xs: 'column-reverse', md: 'row' }}>
        <Grid item xs={12} md={7} flexGrow={1} sx={{ display: 'flex', alignItems: 'center' }}>
          <Box width="100%">
            <Typography color="primary.main" variant="h1" fontFamily="Tropiline">
              {item.textTitle}
            </Typography>
            <Typography
              py={'20px'}
              sx={{ maxWidth: '530px', [theme.breakpoints.down('md')]: { maxWidth: '100%' } }}
            >
              {item.textSubtitle}
            </Typography>
            <Box py={'20px'}>
              <SearchInput
                placeholder={item.searchBarPlaceholder}
                value={searchText}
                onChange={handleChangeSearchText}
              />
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          flexGrow={1}
          sx={{
            textAlign: 'right',
            [theme.breakpoints.down('md')]: { textAlign: 'center', paddingBottom: '60px' }
          }}
        >{item.searchIcon[0]?.url ? (
            <img
              src={item.searchIcon[0]?.url}
              style={{ objectFit: 'cover', objectPosition: 'center', width:"100%", maxWidth: '360px',height:"auto"}}
            />
          ) : (
          <IconSearchAgent width="100%" height="auto" style={{ maxWidth: '360px' }} />

          )}
        </Grid>
      </Grid>
    </Width80Box>
  </Box>
  );
};

