import { Typography, useTheme } from '@mui/material';
import { Box, SxProps } from '@mui/system';
import React from 'react';
import { IconWave } from '../../assets/icons/how-it-works/wave';
import { DARK_TEXT_COLOR, LL_BACKGROUND_COLOR } from '../../colors';
import { WidthAdjustBox } from '../primitives/width-adjust-box';
import { Width80Box } from '../primitives/Width80Box';

export interface TeamMember {
  profileImg: string;
  name: string;
  designation: string;
  themeType?: 'carousel' | 'list';
}

const listType = {
  boxSx: {
    height: '310px',
    width: '310px',
    borderRadius: '500px',
    bgcolor: '#d7e7ec',
    overflow: 'hidden'
  },
  nameSx: {
    color: DARK_TEXT_COLOR
  },
  designationSx: {
    color: DARK_TEXT_COLOR
  }
};
const carouselType = {
  boxSx: {
    height: '254px',
    width: '254px',
    borderRadius: '500px',
    bgcolor: 'white',
    overflow: 'hidden'
  },
  nameSx: {
    color: 'primary.main'
  },
  designationSx: {
    color: 'white',
    fontWeight: 400
  }
};

export const TeamMemberEntity = (props: TeamMember) => {
  const { name, designation, profileImg, themeType } = props;
  let resultTheme = themeType === 'carousel' ? carouselType : listType;
  return (
    <Box mx="auto" padding="0 20px">
      <Box sx={resultTheme.boxSx} style={{ userSelect: 'none' }}>
        <img
          src={profileImg}
          style={{
            objectFit: 'cover',
            height: '100%',
            maxWidth: resultTheme.boxSx.width,
            width: '100%',
            pointerEvents: 'none'
          }}
        />
      </Box>
      <Box mt="25px" textAlign="center" maxWidth={resultTheme.boxSx.width}>
        <Typography sx={resultTheme.nameSx} variant="h6">
          {name}
        </Typography>
        <Typography
          sx={resultTheme.designationSx}
          variant={themeType === 'carousel' ? 'subtitle2' : 'subtitle1'}
        >
          {designation}
        </Typography>
      </Box>
    </Box>
  );
};

export interface ListingTeamProps {
  teamMembers: TeamMember[];
}
export const ListingTeam = (props: ListingTeamProps) => {
  const { teamMembers } = props;
  const theme = useTheme();

  return (
    <>
      <IconWave width="100%" height="auto" marginBottom="-5px" />
      <Box
        sx={{
          background: LL_BACKGROUND_COLOR,
          padding: '40px 0 110px',
          [theme.breakpoints.down('md')]: {
            padding: '40px 0px'
          }
        }}
      >
        <Width80Box>
          <Typography
            color="primary.main"
            fontFamily="Tropiline"
            textAlign="center"
            variant="h3"
            px="20px"
            mx="auto"
            sx={{
              maxWidth: '440px',
              marginBottom: '50px',
              [theme.breakpoints.down('md')]: {
                maxWidth: '100%',
                marginY: '20px'
              }
            }}
          >
            Together, our team will keep you in the loop
          </Typography>
        </Width80Box>
        <Box my="50px">
          <Width80Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr',
              rowGap: '48px',
              columnGap: '28px',
              marginX: 'auto',
              justifyContent: 'center',

              [theme.breakpoints.down(1025)]: {
                display: 'flex',
                gridTemplateColumns: 'none',
                justifyContent: 'normal',
                rowGap: 0,
                columnGap: 0,
                marginY: '20px',
                overflowX: 'scroll'
              }
            }}
          >
            {teamMembers.map((props, i) => {
              return <TeamMemberEntity key={i} {...props} />;
            })}
          </Width80Box>
        </Box>
      </Box>
    </>
  );
};
