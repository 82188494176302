import { Box, Theme } from '@mui/material';
import React from 'react';
import { CraftGql_Body_Carousel_BlockType } from '../../graphql-types';
import { ClientReviewBox } from '../components';
import { Width80Box } from '../components/primitives/Width80Box';

export const ClientReviewCarousel = (props: {
  isMobile: boolean;
  item: CraftGql_Body_Carousel_BlockType;
  theme: Theme;
}) => {
  const { isMobile, item, theme } = props;

  return (
    <Width80Box
      sx={{
        [theme.breakpoints.down(1025)]: {
          paddingX: '20px'
        }
      }}
    >
      <ClientReviewBox isMobile={isMobile} content={item.carouselCard} />
    </Width80Box>
  );
};
