import { Typography } from '@mui/material';
import { Link } from 'gatsby';
import React from 'react';
interface InformationSectionLinkProps {
  href: string;
  label: string;
}

export const InformationSectionLink = (props: InformationSectionLinkProps) => {
  const { href, label } = props;
  return (
    <Typography color="primary.main">
      <Link
        to={href || '#'}
        style={{
          color: 'inherit',
          fontFamily: 'Rubik',
          fontSize: '16px',
          fontWeight: 500,
          textDecoration: 'none',
          textTransform: 'none'
        }}
      >
        {label}
        <span
          style={{
            fontFamily: 'Inter',
            marginLeft: '8px'
          }}
        >
          →
        </span>
      </Link>
    </Typography>
  );
};
