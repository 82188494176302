export function validateEmail(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function isUrlPresent(value: string) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return urlRegex.test(value);
}

export function isAlphaAndSpace(value: string) {
  const regex = /^[a-zA-Z\s]*$/;
  return regex.test(value);
}
