import { Box, Typography, useTheme } from '@mui/material';
import { link } from 'fs-extra';
import React from 'react';
import { InformationSectionLink } from './information-section-link';
import { FlexBox } from './primitives/flex';
import { LLBox } from './primitives/ll-box';

export interface InformationBlockProps {
  heading: string;
  description: string;
  image: JSX.Element;
  imageAlign: 'left' | 'right';
  link?: { label: string; href: string };
  firstBlock?: boolean;
}

export const InformationBlock = (props: InformationBlockProps) => {
  const { heading, description, image, imageAlign, link, firstBlock } = props;
  const theme = useTheme();
  return (
    <FlexBox
      marginTop={
        firstBlock
          ? { xs: '0px', md: '50px', lg: '50px' }
          : { xs: '50px', md: '150px', lg: '150px' }
      }
      sx={{
        flexDirection: imageAlign === 'right' ? 'row' : 'row-reverse',
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column-reverse'
        }
      }}
    >
      <FlexBox
        flex="42.5%"
        flexGrow={1}
        alignItems="center"
        sx={
          imageAlign === 'right'
            ? {
                justifyContent: 'center',
                [theme.breakpoints.down('md')]: {
                  justifyContent: 'flex-start',
                  paddingTop: '25px'
                }
              }
            : {}
        }
      >
        <Box
          sx={{
            width: '100%',
            maxWidth: '360px',
            [theme.breakpoints.down('md')]: {
              paddingTop: imageAlign === 'left' ? '25px' : '0px',
              maxWidth: '100%'
            }
          }}
        >
          <Typography color="primary.main" fontFamily="Tropiline" variant="h3">
            {heading}
          </Typography>
          <Typography paddingTop="30px" lineHeight="26px">
            {description}
          </Typography>
          {link && (
            <Box marginTop="30px">
              <InformationSectionLink href={link.href} label={link.label} />
            </Box>
          )}
        </Box>
      </FlexBox>
      <Box flex="15%"></Box>
      <LLBox flex="42.5%" flexGrow={1} position="relative">
        {image}
      </LLBox>
    </FlexBox>
  );
};
