import React from 'react';
import { IconSvg } from './icon-props';

export const IconHeart = (props: React.CSSProperties) => {
  return (
    <IconSvg
      width={props.width || '33.742'}
      height={props.height || '35.621'}
      viewBox="0 0 33.742 35.621"
      fill={props.fill || '#00a6dd'}
      style={{ ...props }}
    >
      <path
        id="Path_626"
        data-name="Path 626"
        d="M204.447,1296.373a1.48,1.48,0,0,1-.37.051,1.5,1.5,0,0,1-1.1-.461,130.685,130.685,0,0,1-10.713-12.7c-2.974-4.256-4.379-10.219-3.415-14.5a7.887,7.887,0,0,1,3.686-5.351c4.4-2.509,9.415,1.623,12.016,4.314,1.928-3.962,5.781-7.256,10.172-6.9a8.252,8.252,0,0,1,6.711,4.529c1.163,2.377,1.719,6.481-2.238,12.537a160.75,160.75,0,0,1-14.033,18.043A1.5,1.5,0,0,1,204.447,1296.373Zm-9.352-29.135a2.66,2.66,0,0,0-.637.262,4.917,4.917,0,0,0-2.246,3.4c-.78,3.468.024,7.084,2.512,10.644a119.694,119.694,0,0,0,9.307,11.158c2.32-2.638,7.956-8.4,12.653-15.589,2.486-3.8,3.389-8.48,2.229-10.852-.944-1.928-3.059-2.33-4.435-2.443-3.664-.3-6.982,3.476-7.951,7.149a1.5,1.5,0,0,1-2.629.545C202.43,1269.652,198.242,1266.4,195.1,1267.238Z"
        transform="translate(-188.549 -1260.803)"
        fill="#00a6dd"
      />
    </IconSvg>
  );
};
