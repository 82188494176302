import { Box, Grid, useTheme } from '@mui/material';
import React from 'react';
import { CraftGql_FeatureCard_Card_BlockType } from '../../graphql-types';
import { DiscoverPropertyCard } from './discover-property-card';
import { Width80Box } from './primitives/Width80Box';

export interface DiscoverPropertyCardSectionProps {
  discoverProperties: CraftGql_FeatureCard_Card_BlockType[];
}

export const DiscoverPropertyCardSection = (props: DiscoverPropertyCardSectionProps) => {
  const { discoverProperties } = props;
  const theme = useTheme();

  return (
    <Width80Box>
      <Box
        sx={{
          marginTop: '70px',
          [theme.breakpoints.down('md')]: {
            marginTop: '0px'
          }
        }}
      />
      <Grid
        container
        spacing={{ xs: 5, md: 6 }}
        justifyContent="center"
        sx={{
          flexDirection: 'row',
          marginTop: '56px',
          justifyContent: 'space-between',
          [theme.breakpoints.down('md')]: {
            marginTop: '0px'
          }
        }}
      >
        {discoverProperties.map(
          ({
            cardImage,
            imageBackgroundColor,
            cardTitle,
            cardSubTitle,
            cardLinkText,
            cardLink
          }) => (
            <Grid
              item
              lg={4}
              md={6}
              sm={6}
              xs={12}
              key={cardTitle}
              padding={0}
              // marginRight="15px"
              display="flex"
              sx={{
                flexGrow: 1,
                [theme.breakpoints.down('md')]: {
                  // marginBottom: '40px',
                  marginRight: '0px'
                }
              }}
            >
              <DiscoverPropertyCard
                description={cardSubTitle}
                heading={cardTitle}
                button={{ link: cardLink, text: cardLinkText }}
                backgroundColor={imageBackgroundColor}
                image={
                  cardImage?.length > 0 ? (
                    <img
                      src={cardImage[0].url}
                      width="100%"
                      height="200px"
                      style={{ objectFit: 'cover' }}
                    />
                  ) : (
                    <></>
                  )
                }
              />
            </Grid>
          )
        )}
      </Grid>
    </Width80Box>
  );
};
