import React from 'react';
import { Box, Theme } from '@mui/material';
import { CraftGql_Body_WhiteSpaceElement_BlockType } from '../../graphql-types';

export const WhiteSpaceElement = (props: {
  theme: Theme;
  item: CraftGql_Body_WhiteSpaceElement_BlockType;
}) => {
  const { item } = props;
  return <Box height={{ xs: Math.ceil(item.height / 2.5), md: item.height }} width="100%" />;
};
