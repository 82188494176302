import React from 'react';
import { IconSvg } from '../icon-props';

export const IconEnvelope = (props: React.CSSProperties) => {
  return (
    <IconSvg
      width={props.width || '464.987'}
      height={props.height || '340.803'}
      viewBox="0 0 464.987 340.803"
      fill={props.fill}
      style={{ ...props, maxWidth: '465px' }}
    >
      <g id="Group_274" data-name="Group 274" transform="translate(-6616.75 -5982.956)">
        <path
          id="Path_523"
          data-name="Path 523"
          d="M7064.242,6232.854h-.016a2.046,2.046,0,0,1,.006-4.092h.014c2.215,0,7.4-2.665,11.47-5.093a91.3,91.3,0,0,1-11.357-9.411,2.046,2.046,0,0,1,2.864-2.923c.081.079,8.182,7.981,13.342,10.417a2.047,2.047,0,0,1,.248,3.563C7078.888,6226.575,7069.052,6232.853,7064.242,6232.854Z"
          fill="#00a6dd"
        />
        <path
          id="Path_524"
          data-name="Path 524"
          d="M6997.34,6294.05a19.888,19.888,0,0,1-8.358-1.887c-10.452-4.825-13.757-19.013-8.036-34.5a57.094,57.094,0,0,1,14.806-21.853,61.885,61.885,0,0,0-8.553-3.362c-20.823-6.534-40.739-4.572-59.193,5.832a79.182,79.182,0,0,0-21.707,17.8,2.047,2.047,0,0,1-3.234-2.509,81.76,81.76,0,0,1,22.785-18.774c13.418-7.6,31.062-17.745,58.47-9.146,4.043,1.268,11.686,5.639,14.78,7.259a64.836,64.836,0,0,1,12.89-8.087,76.07,76.07,0,0,1,28.513-6.986c11.5-.642,23.979.908,37.077,4.606a2.047,2.047,0,1,1-1.112,3.939c-34.836-9.839-58.655-2.759-73.558,8.729,12.33,7.858,16.115,18.049,17.24,24.347,2.071,11.593-2.21,23.764-10.653,30.285A19.777,19.777,0,0,1,6997.34,6294.05Zm2.243-56.176a54.492,54.492,0,0,0-14.8,21.2c-4.95,13.4-3.117,22.749,5.314,26.639a15.2,15.2,0,0,0,16.3-1.946c7.155-5.525,11.5-13.622,9.723-23.6C7015.094,6254.429,7011.529,6245.027,6999.583,6237.874Z"
          fill="#00a6dd"
        />
        <path
          id="Path_525"
          data-name="Path 525"
          d="M6691.439,6199.343c-20.59,0-45.776-5.994-62.059-30.706a81.741,81.741,0,0,1-12.621-34.272,2.046,2.046,0,0,1,4.076-.366,110.762,110.762,0,0,0,14.295,31.724c13.867,20.942,33.78,31.322,63.615,29.273a2.046,2.046,0,1,1,.28,4.083C6696.589,6199.246,6694.051,6199.343,6691.439,6199.343Z"
          fill="#00a6dd"
        />
        <path
          id="Path_526"
          data-name="Path 526"
          d="M6919.473,6178.275l-5.16-.5c.214-.733,20.339-73.729,23.148-108.136,2.353-28.822.056-46.02-.676-50.689l-172-29.906c2.118,12.612,6.648,61.531-5.408,96.429-13.456,38.952-17.764,48.4-17.941,48.782l-3.716-1.714c.042-.093,4.444-9.774,17.789-48.4,6.9-19.969,7.1-45.586,6.058-63.558a302.008,302.008,0,0,0-3.99-34.719l-.553-2.9,185.789,32.185.284,1.36c.149.713,3.6,17.842.751,52.75C6941,6104.069,6919.688,6177.536,6919.473,6178.275Z"
          fill="#00a6dd"
        />
        <path
          id="Path_527"
          data-name="Path 527"
          d="M6878.744,6323.759l-1.749-.437c-.237-.059-6.017-1.525-24.47-7.913-18.3-6.335-175.5-48.836-177.084-49.265l-1.675-.452.173-1.727c.03-.3.891-7.8,13.714-39.86,12.694-31.735,38.291-107.746,38.548-108.51l.264-.785,22.94-12.9-2.534,7.1-17.055,8.678c-2.764,8.19-26.325,77.845-38.363,107.941-9.421,23.552-12.263,33.5-13.1,37.065,18.618,5.036,147.821,37.976,165.393,44.059,12.84,4.445,28.686,9.084,31.54,9.913,3.6-9.212,21.025-52.313,32.44-77.047,11.814-25.6,26.41-64.113,28.342-69.762L6928,6146.517l1.859-6.42,10.539,29.731-.227.67c-.151.444-15.189,44.728-27.95,72.375-12.689,27.5-32.622,78.691-32.822,79.205Z"
          fill="#151f35"
        />
        <path
          id="Path_528"
          data-name="Path 528"
          d="M6875.845,6322.552c-.242-.328-31.235-37.995-52.378-62.661-21.269-24.815-96.2-141.279-97.024-142.5l3.4-2.284c.821,1.221,82.356,122.382,103.472,147.017,21.239,24.78,45.582,57.668,45.825,58Z"
          fill="#151f35"
        />
        <path
          id="Path_529"
          data-name="Path 529"
          d="M6676.77,6266.054l-1.59-3.771c2.981-1.257,73.268-30.851,96-37.243,11.2-3.15,29.489-9.694,48.854-16.623,19.762-7.071,40.2-14.383,54.033-18.388,26.75-7.744,63.057-21.95,63.42-22.093l1.494,3.811c-.365.143-35.861,15.015-62.794,22.812-13.715,3.971-34.089,11.26-53.792,18.31-19.434,6.953-37.79,13.522-49.125,16.71C6750.784,6235.9,6677.5,6265.747,6676.77,6266.054Z"
          fill="#151f35"
        />
        <path
          id="Path_530"
          data-name="Path 530"
          d="M6813.416,6024.541l-34.777,23.82,11.434,3.812-6.193,27.631,36.206,9.051,6.67-28.107,10,1.429Z"
          fill="#d1d4e7"
        />
        <path
          id="Path_531"
          data-name="Path 531"
          d="M6917.864,6074.164a1.824,1.824,0,0,1-.2-.01c-9.8-.951-64.282-11.489-66.6-11.937a2.047,2.047,0,0,1,.778-4.019c.565.11,53.012,8.795,62.467,10.765,4.055.845,5.694,2.228,5.585,3.353A2.047,2.047,0,0,1,6917.864,6074.164Z"
          fill="#151f35"
        />
        <path
          id="Path_532"
          data-name="Path 532"
          d="M6903.33,6060.684c-.057,0-17.852-2.862-17.91-2.872-10.137-1.859-29.842-7-31.548-7.372a2.046,2.046,0,0,1,.86-4c.415.089,41.6,8.931,48.57,9.518a2.046,2.046,0,0,1,1.867,2.212C6905.079,6059.237,6904.383,6060.684,6903.33,6060.684Z"
          fill="#151f35"
        />
        <path
          id="Path_533"
          data-name="Path 533"
          d="M6913.655,6086.074a2.069,2.069,0,0,1-.411-.041c-4.617-.94-64.386-12.742-64.99-12.861a2.046,2.046,0,1,1,.793-4.014c.6.118,31.069,6.3,35.7,7.24,1.108.225,31.428,4.731,30.914,8.038A2,2,0,0,1,6913.655,6086.074Z"
          fill="#151f35"
        />
        <path
          id="Path_534"
          data-name="Path 534"
          d="M6910.381,6098.936a2.106,2.106,0,0,1-.454-.05c-4.152-.94-64.84-14.154-65.453-14.288a2.082,2.082,0,0,1-1.564-2.435c.439-2.555,7.535-1.372,17,.824.611.142,46.755,10.964,50.924,11.907a2.046,2.046,0,0,1-.45,4.042Z"
          fill="#151f35"
        />
        <path
          id="Path_535"
          data-name="Path 535"
          d="M6828.819,6134.329a1.969,1.969,0,0,1,.2.014c9.782,1.161,64.021,12.866,66.325,13.363a2.047,2.047,0,0,1-.864,4c-.563-.122-52.812-9.93-62.222-12.1-4.036-.931-5.645-2.349-5.512-3.472A2.047,2.047,0,0,1,6828.819,6134.329Z"
          fill="#151f35"
        />
        <path
          id="Path_536"
          data-name="Path 536"
          d="M6831.433,6122.8c.058,0,17.794,3.2,17.852,3.216,10.1,2.053,29.7,7.577,31.4,7.976a2.046,2.046,0,0,1-.937,3.984c-.414-.1-41.418-9.728-48.378-10.45a2.045,2.045,0,0,1-1.824-2.246C6829.657,6124.212,6830.381,6122.779,6831.433,6122.8Z"
          fill="#151f35"
        />
        <path
          id="Path_537"
          data-name="Path 537"
          d="M6890.845,6162.952a2.071,2.071,0,0,1-.411-.041c-4.617-.94-64.386-12.742-64.99-12.861a2.046,2.046,0,1,1,.793-4.014c.6.119,31.069,6.3,35.7,7.24,1.108.226,31.428,4.731,30.914,8.038A2,2,0,0,1,6890.845,6162.952Z"
          fill="#151f35"
        />
        <path
          id="Path_538"
          data-name="Path 538"
          d="M6887.571,6175.814a2.16,2.16,0,0,1-.454-.049c-4.152-.941-64.84-14.155-65.453-14.289a2.082,2.082,0,0,1-1.564-2.435c.439-2.555,7.535-1.372,17,.824.611.142,46.755,10.965,50.924,11.907a2.046,2.046,0,0,1-.45,4.042Z"
          fill="#151f35"
        />
        <path
          id="Path_539"
          data-name="Path 539"
          d="M6793.14,6100.6l-34.777,23.82,11.433,3.812-6.193,27.631,36.206,9.051,6.669-28.107,10,1.429Z"
          fill="#d1d4e7"
        />
        <g id="Group_272" data-name="Group 272">
          <path
            id="Path_540"
            data-name="Path 540"
            d="M6807.169,6069.531c-4.752,0-9.27-5.163-9.788-5.773a2.046,2.046,0,0,1,3.122-2.646c1.508,1.77,4.705,4.509,6.866,4.319a2.607,2.607,0,0,0,2.33-1.844c.31-1.156-.84-3.708-2.34-4.762-4.041-2.836-5.994-4.968-6.174-8.088a6.41,6.41,0,0,1,2.287-5.287c3.971-3.533,9.053-.175,11.43,2.367a2.047,2.047,0,0,1-2.987,2.8h0c-.036-.037-4.136-3.992-6.076-2.266a2.386,2.386,0,0,0-.568,2.152c.1,1.809,1.984,4.207,4.907,6.26,2.945,2.067,4.244,5.015,3.474,7.885a6.706,6.706,0,0,1-5.923,4.86C6807.542,6069.524,6807.355,6069.531,6807.169,6069.531Z"
            fill="#171f33"
          />
          <path
            id="Path_541"
            data-name="Path 541"
            d="M6810.232,6047.843c-.165,0-1.691-.465-1.859-.507-1.1-.275-1.767-.426-1.492-1.522l.744-2.963a2.047,2.047,0,0,1,3.97,1l-.744,2.963C6810.617,6047.739,6811.148,6047.843,6810.232,6047.843Z"
            fill="#171f33"
          />
          <path
            id="Path_542"
            data-name="Path 542"
            d="M6804.469,6072.037a2.048,2.048,0,0,1-1.986-2.545l.744-2.962a2.047,2.047,0,0,1,3.97,1l-.745,2.962A2.047,2.047,0,0,1,6804.469,6072.037Z"
            fill="#171f33"
          />
        </g>
        <g id="Group_273" data-name="Group 273">
          <path
            id="Path_543"
            data-name="Path 543"
            d="M6786.894,6147.254c-4.752,0-9.271-5.163-9.788-5.773a2.046,2.046,0,1,1,3.122-2.646c1.508,1.769,4.709,4.488,6.865,4.318a2.607,2.607,0,0,0,2.331-1.843c.31-1.156-.373-2.423-1.873-3.476-4.041-2.836-6.462-6.253-6.642-9.375a6.411,6.411,0,0,1,2.287-5.285c3.972-3.535,9.053-.175,11.43,2.366a2.046,2.046,0,0,1-2.986,2.8h0c-.037-.038-3.782-3.833-5.724-2.107a2.366,2.366,0,0,0-.921,1.993c.1,1.81,2.208,3.57,5.13,5.621a8.076,8.076,0,0,1,3.252,8.525,6.708,6.708,0,0,1-5.924,4.86C6787.266,6147.247,6787.08,6147.254,6786.894,6147.254Z"
            fill="#171f33"
          />
          <path
            id="Path_544"
            data-name="Path 544"
            d="M6788.592,6126.081a2.047,2.047,0,0,1-1.986-2.545l.744-2.961a2.046,2.046,0,1,1,3.969,1l-.744,2.961A2.046,2.046,0,0,1,6788.592,6126.081Z"
            fill="#171f33"
          />
          <path
            id="Path_545"
            data-name="Path 545"
            d="M6784.194,6149.76a2.046,2.046,0,0,1-1.986-2.545l.744-2.963a2.046,2.046,0,0,1,3.969,1l-.744,2.962A2.047,2.047,0,0,1,6784.194,6149.76Z"
            fill="#171f33"
          />
        </g>
      </g>
    </IconSvg>
  );
};
