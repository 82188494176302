import { Theme } from '@mui/material';
import React from 'react';
import { CraftGql_Body_AustraliaLeadingPointsSection_BlockType } from '../../graphql-types';
import { ListingLoopPointsSection } from '../components';
import { Width80Box } from '../components/primitives/Width80Box';

export const AustraliaLeadingPointsSection = (props: {
  item: CraftGql_Body_AustraliaLeadingPointsSection_BlockType;
  theme: Theme;
}) => {
  const { item } = props;
  const points = item.buyersDotPoints.map((point) => ({ text: point.col1 }));
  return (
    <>
      <Width80Box
        marginTop={{ xs: '40px', sm: '40px', md: '40px', lg: '110px' }}
        marginX="auto"
        px={'20px'}
      >
        <ListingLoopPointsSection points={points} />
      </Width80Box>
    </>
  );
};
