import { Theme } from '@mui/material';
import React from 'react';
import { AgentReviewsSection } from './sections/agent-reviews-section';
import { AgentsBuyersDotPointsSection } from './sections/agents-buyers-dot-points-section';
import { AustraliaLeadingPointsSection } from './sections/australia-leading-points-section';
import { AustraliasFastestSection } from './sections/australias-fastest-section';
import { ClientReviewCarousel } from './sections/client-review-carousel';
import CtaButton from './sections/cta-button';
import DownloadAppSection from './sections/download-app-section';
import { FaqsSection } from './sections/faqs-section';
import { FeatureNewsSection } from './sections/feature-news-section';
import { FindAnAgentSection } from './sections/find-an-agent-section';
import { IllustrationSection } from './sections/illustration-section';
import { InformationSection } from './sections/information-section';
import LatestPropertyMatches from './sections/latest-property-matchs-section';
import ListingLoopFeatures from './sections/listing-loop-features';
import { ListingTeamMemberSection } from './sections/listing-team-member-section';
import { NewsLetterSection } from './sections/news-letter-section';
import PricingSection from './sections/pricing-section';
import { QuoteSection } from './sections/quote-section';
import RibbonSection from './sections/ribbon-section';
import { SingleVideoSection } from './sections/single-video-section';
import TextSection from './sections/text-section';
import { WhiteSpaceElement } from './sections/white-space-element';

export enum ReusableComponentsType {
  informationBlock = 'informationBlock',
  downloadApp = 'downloadApp',
  propertyMatches = 'propertyMatches',
  illustrationBlock = 'illustrationBlock',
  ctaButton = 'ctaButton',
  carousel = 'carousel',
  features = 'features',
  videoGraphicText = 'videoGraphicText',
  agentsReviewBlock = 'agentsReviewBlock',
  priceCards = 'priceCards',
  listingTeamMembersBlock = 'listingTeamMembersBlock',
  australiaLeadingPointsSection = 'australiaLeadingPointsSection',
  ribbonSection = 'ribbonSection',
  agentsBuyersDotPointsSection = 'agentsBuyersDotPointsSection',
  featuredNews = 'featuredNews',
  tabs = 'tabs',
  text = 'text',
  image = 'image',
  quotesBlock = 'quotesBlock',
  australiasFastestGrowing = 'australiasFastestGrowing',
  newsLetter = 'newsLetter',
  faqs = 'faqs',
  findAnAgent = 'findAnAgent',
  whiteSpaceElement = 'whiteSpaceElement'
}

export const REUSABLE_COMPONENT: Record<
  string,
  React.ComponentType<{ item: any; theme: Theme; isMobile: boolean }>
> = {
  informationBlock: InformationSection,
  downloadApp: DownloadAppSection,
  propertyMatches: LatestPropertyMatches,
  illustrationBlock: IllustrationSection,
  ctaButton: CtaButton,
  carousel: ClientReviewCarousel,
  features: ListingLoopFeatures,
  videoGraphicText: SingleVideoSection,
  agentsReviewBlock: AgentReviewsSection,
  priceCards: PricingSection,
  listingTeamMembersBlock: ListingTeamMemberSection,
  australiaLeadingPointsSection: AustraliaLeadingPointsSection,
  ribbonSection: RibbonSection,
  agentsBuyersDotPointsSection: AgentsBuyersDotPointsSection,
  featuredNews: FeatureNewsSection,
  text: TextSection,
  quotesBlock: QuoteSection,
  australiasFastestGrowing: AustraliasFastestSection,
  newsLetter: NewsLetterSection,
  faqs: FaqsSection,
  findAnAgent: FindAnAgentSection,
  whiteSpaceElement: WhiteSpaceElement
};
