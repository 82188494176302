import { Box, Grid, Theme, Typography } from '@mui/material';
import React from 'react';
import {
  CraftGql_Body_PriceCards_BlockType,
  CraftGql_PriceCard_PriceCardDetails_BlockType
} from '../../graphql-types';
import { PriceCard } from '../components/agents/price-card';
import { Width80Box } from '../components/primitives/Width80Box';
import Tabs from '../components/tabs';

const PricingSection = (props: { theme: Theme; item: CraftGql_Body_PriceCards_BlockType }) => {
  const { theme, item } = props;
  const [activeTabIndex, setActiveTabIndex] = React.useState<number>(0);
  const viewType = activeTabIndex === 0 ? 'agent' : 'agency';
  const { priceCard } = item;
  let agentPriceCards: CraftGql_PriceCard_PriceCardDetails_BlockType[] = [],
    agencyPriceCards: CraftGql_PriceCard_PriceCardDetails_BlockType[] = [];
  priceCard.map((card) =>
    card.cardFor === 'agent' ? agentPriceCards.push(card) : agencyPriceCards.push(card)
  );
  const tabs = [
    {
      label: 'View agents',
      value: 'agent'
    },
    {
      label: 'View agencies',
      value: 'agency'
    }
  ];
  return (
    <>
      <Box paddingY={{ xs: '40px', md: '90px', lg: '90px' }} paddingX={'20px'}>
        <Typography
          color="primary.main"
          fontFamily="Tropiline"
          paddingX="20px"
          textAlign="center"
          variant="h1"
        >
          Listing Loop Pricing
        </Typography>
        <Box sx={{ paddingTop: '50px', [theme.breakpoints.down('md')]: { paddingY: '30px' } }}>
          <Tabs
            activeTabIndex={activeTabIndex}
            handleActiveTab={(index) => setActiveTabIndex(index)}
            tabs={tabs}
          />
        </Box>
        <Width80Box paddingY={{ xs: '30px', md: '50px' }}>
          <Grid container columns={12} spacing={8} justifyContent="center">
            {viewType === 'agent'
              ? agentPriceCards.map((card) => (
                  <Grid key={card.id} item xs={12} sm={5} lg={4} flexGrow={1}>
                    <PriceCard
                      theme={theme}
                      cardColor={card.cardColor || '#0C2340'}
                      buttonLabel="Get started"
                      onClick={() => {
                        console.log(card.cardButton);
                      }}
                      price={card.price}
                      priceTitle={card.cardTitle}
                      description={card.cardDescription}
                      populor={card.popular === 'yes'}
                    />
                  </Grid>
                ))
              : agencyPriceCards.map((card) => (
                  <Grid key={card.id} item xs={12} sm={5} lg={4} flexGrow={1}>
                    <PriceCard
                      theme={theme}
                      cardColor={card.cardColor || '#0C2340'}
                      buttonLabel="Get started"
                      onClick={() => {
                        console.log(card.cardButton);
                      }}
                      price={card.price}
                      priceTitle={card.cardTitle}
                      description={card.cardDescription}
                      populor={card.popular === 'yes'}
                    />
                  </Grid>
                ))}
          </Grid>
        </Width80Box>
      </Box>
    </>
  );
};

export default PricingSection;
