import React from 'react';
import { IconSvg } from '../icon-props';

export const DisorderLine = (props: React.CSSProperties) => {
  return (
    <IconSvg
      width={props.width || '1848'}
      height={props.height || '121'}
      viewBox="0 0 1848 121"
      style={{ transform: 'rotateX(180deg)', ...props }}
    >
      <path
        fill={props.fill || 'rgba(241, 243, 244, 1)'}
        d="M 0 111 C 0.5 111 -1.5 104 -1 104 L -1 104 L -1 0 L 0 0 Z"
        stroke-width="0"
      ></path>
      <path
        fill={props.fill || 'rgba(241, 243, 244, 1)'}
        d="M -2 104 C 119 104 119 100 240 100 L 240 100 L 240 0 L -2 0 Z"
        stroke-width="0"
      ></path>
      <path
        fill={props.fill || 'rgba(241, 243, 244, 1)'}
        d="M 239 100 C 309.5 100 309.5 108 380 108 L 380 108 L 380 0 L 239 0 Z"
        stroke-width="0"
      ></path>
      <path
        fill={props.fill || 'rgba(241, 243, 244, 1)'}
        d="M 379 108 C 463.5 108 463.5 100 548 100 L 548 100 L 548 0 L 379 0 Z"
        stroke-width="0"
      ></path>
      <path
        fill={props.fill || 'rgba(241, 243, 244, 1)'}
        d="M 547 100 C 614.5 100 614.5 100 682 100 L 682 100 L 682 0 L 547 0 Z"
        stroke-width="0"
      ></path>
      <path
        fill={props.fill || 'rgba(241, 243, 244, 1)'}
        d="M 681 100 C 754 100 754 97 827 97 L 827 97 L 827 0 L 681 0 Z"
        stroke-width="0"
      ></path>
      <path
        fill={props.fill || 'rgba(241, 243, 244, 1)'}
        d="M 826 97 C 895.5 97 895.5 102 965 102 L 965 102 L 965 0 L 826 0 Z"
        stroke-width="0"
      ></path>
      <path
        fill={props.fill || 'rgba(241, 243, 244, 1)'}
        d="M 964 102 C 1049 102 1049 99 1134 99 L 1134 99 L 1134 0 L 964 0 Z"
        stroke-width="0"
      ></path>
      <path
        fill={props.fill || 'rgba(241, 243, 244, 1)'}
        d="M 1133 99 C 1229.5 99 1229.5 101 1326 101 L 1326 101 L 1326 0 L 1133 0 Z"
        stroke-width="0"
      ></path>
      <path
        fill={props.fill || 'rgba(241, 243, 244, 1)'}
        d="M 1325 101 C 1390.5 101 1390.5 109 1456 109 L 1456 109 L 1456 0 L 1325 0 Z"
        stroke-width="0"
      ></path>
      <path
        fill={props.fill || 'rgba(241, 243, 244, 1)'}
        d="M 1455 109 C 1542.5 109 1542.5 103 1630 103 L 1630 103 L 1630 0 L 1455 0 Z"
        stroke-width="0"
      ></path>
      <path
        fill={props.fill || 'rgba(241, 243, 244, 1)'}
        d="M 1629 103 C 1707 103 1707 109 1785 109 L 1785 109 L 1785 0 L 1629 0 Z"
        stroke-width="0"
      ></path>
      <path
        fill={props.fill || 'rgba(241, 243, 244, 1)'}
        d="M 1784 109 C 1816 109 1816 112 1848 112 L 1848 112 L 1848 0 L 1784 0 Z"
        stroke-width="0"
      ></path>
    </IconSvg>
  );
};
