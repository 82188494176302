import React from 'react';
import { Box, Typography, Theme, Grid } from '@mui/material';
import { CraftGql_Body_IllustrationBlock_BlockType } from '../../graphql-types';
import { PrimaryButton } from '../components/primitives/primary-button';
import { IconDiscoverProperty } from '../assets/icons';
import { Width80Box } from '../components/primitives/Width80Box';

export const IllustrationSection = (props: {
  theme: Theme;
  item: CraftGql_Body_IllustrationBlock_BlockType;
}) => {
  const { item, theme } = props;
  return (
    <Box>
      <style>{`
        .illustrationBlockText strong {
          color: ${theme.palette.primary.main};
          font-family: 'Tropiline';
        }
        `}</style>
      <Width80Box
        sx={{
          [theme.breakpoints.down(1025)]: {
            paddingX: '20px'
          }
        }}
      >
        <Grid
          container
          alignItems="center"
          direction={{
            xs: 'column-reverse',
            md: item.imageDirection === 'left' ? 'row-reverse' : 'row'
          }}
          marginY="40px"
          maxWidth="100%"
        >
          <Grid item xs={12} md={5} columnSpacing="20px" textAlign="left">
            <Typography
              color="secondary"
              fontWeight={500}
              textAlign={{ xs: 'center', md: 'left', lg: 'left' }}
              variant="h3"
              className="illustrationBlockText"
              sx={{ lineHeight: '40px', [theme.breakpoints.down('md')]: { lineHeight: '30px' } }}
              dangerouslySetInnerHTML={{ __html: item.richTextContent }}
            ></Typography>
            {item.primaryButton && item.primaryButton[0] && (
              <Box pt="40px" textAlign={{ xs: 'center', md: 'left', lg: 'left' }}>
                <PrimaryButton
                  variant="contained"
                  onClick={() => console.log(item.primaryButton[0].buttonDestinationUrl)}
                >
                  {item.primaryButton[0].buttonLabel}
                </PrimaryButton>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} md={2}></Grid>
          <Grid
            item
            xs={12}
            md={5}
            textAlign="center"
            paddingX="26px"
            sx={{
              [theme.breakpoints.down('md')]: {
                marginBottom: '32px'
              }
            }}
          >
            {item.imageElement[0] ? (
              <img
                src={item.imageElement[0].url}
                style={{ objectFit: 'cover', objectPosition: 'center', width: '100%' }}
              />
            ) : (
              <IconDiscoverProperty />
            )}
          </Grid>
        </Grid>
      </Width80Box>
    </Box>
  );
};
