import React from 'react';
import { Theme, Typography } from '@mui/material';
import { CraftGql_Body_Text_BlockType } from '../../graphql-types';

const TextSection = (props: { theme: Theme; item: CraftGql_Body_Text_BlockType }) => {
  const { item } = props;
  return <Typography>{item.bodyText}</Typography>;
};

export default TextSection;
