import React from 'react';
import { Box, Typography, Theme, Grid } from '@mui/material';
import { DARK_NAVY_COLOR } from '../colors';
import { PrimaryButton } from '../components/primitives/primary-button';
import { ListingTeamCarousel } from '../components/buyers/listing-team-carousel';
import { BuyerProfileImage } from '../assets/images';
import { CraftGql_Body_ListingTeamMembersBlock_BlockType } from '../../graphql-types';
import { ListingTeam } from '../components/about-us/listing-team';

export const ListingTeamMemberSection = (props: {
  theme: Theme;
  item: CraftGql_Body_ListingTeamMembersBlock_BlockType;
}) => {
  const { item, theme } = props;
  //teamMemberComponentType when list uses the about-us page styling
  const type= item.teamMemberComponentType;

  const heading = item.bodyText || 0;
  const buttonText = item.buttonTitle || 0;


  console.log(item, '%%')
  const { listingMember } = item;

  

  //   const teamMembers = [
  //     {
  //       profileImg: BuyerProfileImage,
  //       name: 'Rhett Dallwitz',
  //       designation: 'Co-Founder & CEO'
  //     },
  //     {
  //       profileImg: BuyerProfileImage,
  //       name: 'Rhett Dallwitz',
  //       designation: 'Co-Founder & CEO'
  //     },
  //     {
  //       profileImg: BuyerProfileImage,
  //       name: 'Rhett Dallwitz',
  //       designation: 'Co-Founder & CEO'
  //     },
  //     {
  //       profileImg: BuyerProfileImage,
  //       name: 'Rhett Dallwitz',
  //       designation: 'Co-Founder & CEO'
  //     },
  //     {
  //       profileImg: BuyerProfileImage,
  //       name: 'Rhett Dallwitz',
  //       designation: 'Co-Founder & CEO'
  //     },
  //     {
  //       profileImg: BuyerProfileImage,
  //       name: 'Rhett Dallwitz',
  //       designation: 'Co-Founder & CEO'
  //     },
  //     {
  //       profileImg: BuyerProfileImage,
  //       name: 'Rhett Dallwitz',
  //       designation: 'Co-Founder & CEO'
  //     },
  //     {
  //       profileImg: BuyerProfileImage,
  //       name: 'Rhett Dallwitz',
  //       designation: 'Co-Founder & CEO'
  //     },
  //     {
  //       profileImg: BuyerProfileImage,
  //       name: 'Rhett Dallwitz',
  //       designation: 'Co-Founder & CEO'
  //     }
  //   ];

  const teamMembers = listingMember.map((member) => ({
    profileImg: member.memberProfileImage[0].url,
    name: member.memberName,
    designation: member.memberDesignation
  }));

  return (
    <>
    {type == 'list' ?  <Box paddingTop={{ xs: '40px', md: '90px', lg: '90px' }}>
        <ListingTeam teamMembers={teamMembers} />
      </Box> : 
      <Box paddingY={'40px'} bgcolor={DARK_NAVY_COLOR}>
        {heading &&
        <Typography
          color="white"
          paddingTop="30px"
          px={'20px'}
          variant="h3"
          fontWeight="500"
          mb="30px"
          mx="auto"
          sx={{ maxWidth: '626px', [theme.breakpoints.down('md')]: { maxWidth: '100%' } }}
        >
          {heading}
        </Typography>}
        <ListingTeamCarousel teamMembers={teamMembers} />
        <Box textAlign={'center'} marginBottom="-64px">
          {buttonText &&
          <PrimaryButton
            sx={{
              textTransform: 'none'
            }}
            variant="contained"
          >
            {buttonText}
          </PrimaryButton>}
        </Box>
      </Box>}
    </>
  );
};
