import { Box, Grid, Theme, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import React from 'react';
import { CraftGql_Body_AgentsReviewBlock_BlockType } from '../../graphql-types';
import { DisorderLine } from '../assets/icons/pattern/disorder-line';
import { DARK_NAVY_COLOR } from '../colors';
import { Width80Box } from '../components/primitives/Width80Box';
import { VideoCard } from '../components/video-card';

type ClassName = 'reviewHeading' | 'reviewContainer' | 'disorderLine2' | 'disorderLine1';

interface StyleProps {
  [className: string]: SxProps;
}

export const AgentReviewsSection = (props: {
  theme: Theme;
  item: CraftGql_Body_AgentsReviewBlock_BlockType;
}) => {
  const { theme, item } = props;
  const { agentReviewCard, bodyText } = item;

  // Styles
  const sxProps: StyleProps = {
    reviewHeading: {
      maxWidth: '626px',
      mx: 'auto',
      mb: '60px',
      fontWeight: 500,
      px: '20px',
      pt: '30px',
      color: 'white',

      [theme.breakpoints.down('md')]: { maxWidth: '100%', mb: '60px' }
    },
    reviewContainer: {
      py: '40px',
      px: '20px',
      bgcolor: DARK_NAVY_COLOR
    },
    disorderLine2: {
      height: '80px',
      marginBottom: '-65px',
      overflow: 'hidden'
    },
    disorderLine1: {
      height: '40px',
      overflow: 'hidden'
    }
  };

  return (
    <>
      <Box sx={sxProps.disorderLine1}>
        <DisorderLine
          marginTop="-20px"
          width="100%"
          fill={DARK_NAVY_COLOR}
          transform="scaleX(1.5)"
        />
      </Box>
      <Box sx={sxProps.reviewContainer}>
        <Typography variant="h3" sx={sxProps.reviewHeading}>
          {bodyText || 'What agents say about Listing Loop'}
        </Typography>
        <Width80Box>
          <Grid container spacing={{ xs: '30px', md: '40px', lg: '60px' }} justifyContent="center">
            {agentReviewCard.map((agentReview, index) => (
              <Grid item lg={4} md={6} sm={6} xs={12} key={index}>
                <VideoCard
                  theme={theme}
                  agencyName={agentReview.agencyName}
                  name={agentReview.agentName}
                  videoURL={agentReview.videoUrl}
                  agencyLogo={agentReview.agencyLogo[0]?.url || ''}
                  agencyColor={agentReview.agencyColorCode}
                />
              </Grid>
            ))}
          </Grid>
        </Width80Box>
      </Box>
      <Box sx={sxProps.disorderLine2}>
        <DisorderLine
          marginTop="-50px"
          width="100%"
          fill={DARK_NAVY_COLOR}
          transform="rotateX(0deg)scaleX(1.5)"
        />
      </Box>
    </>
  );
};
