import { Box, Theme, Typography } from '@mui/material';
import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import {
  CraftGql_Body_FeaturedNews_BlockType,
  CraftGql_LatestNews_Default_EntryConnection
} from '../../graphql-types';
import { CAPTIONS_TEXT_COLOR } from '../colors';
import { NewsCard } from '../components/news-card';
import { Width80Box } from '../components/primitives/Width80Box';

export interface FeatureNewsSectionProps {
  allCraftGqlLatestNewsDefaultEntry: CraftGql_LatestNews_Default_EntryConnection;
}

export const FeatureNewsSection = (props: {
  theme: Theme;
  item: CraftGql_Body_FeaturedNews_BlockType;
}) => {
  const { item, theme } = props;

  return (
    <StaticQuery
      query={graphql`
        {
          allCraftGqlLatestNewsDefaultEntry(limit: 3, sort: { fields: postDate, order: DESC }) {
            edges {
              node {
                slug
                id
                newsTags {
                  ... on CraftGQL_newsTags_Tag {
                    id
                    title
                  }
                }
                postDate
                heroBackgroundImage {
                  url
                }
                title
              }
            }
          }
        }
      `}
      render={(data: FeatureNewsSectionProps) => {
        console.log('Data:', data);
        const newsArticles = data.allCraftGqlLatestNewsDefaultEntry.edges;
        return (
          <>
            <Box
              display="flex"
              justifyContent="center"
              flexDirection="column"
              maxWidth="560px"
              py={'20px'}
              mx={'auto'}
            >
              <Typography
                color="primary.main"
                fontFamily="Tropiline"
                marginTop="32px"
                textAlign="center"
                variant="h3"
              >
                {item.textTitle || 'Featured News'}
              </Typography>
              <Typography color={CAPTIONS_TEXT_COLOR} my="20px" textAlign="center" variant="body1">
                {item.textSubtitle ||
                  `Discover off-market properties in Melbourne, Sydney, Brisbane, Adelaide, Perth, Canberra,
          Hobart and everywhere in between.`}
              </Typography>
            </Box>
            <Width80Box
              theme={theme}
              sx={{
                columnGap: '60px',
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr',
                justifyContent: 'center',
                margin: 'auto',
                marginTop: '20px',
                [theme.breakpoints.down('md')]: {
                  columnGap: '16px',
                  px: '20px',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  overflowX: 'auto'
                }
              }}
            >
              {newsArticles.map(({ node }) => {
                const date = Date.parse(node.postDate);
                const image =
                  node.heroBackgroundImage?.length > 0 ? node.heroBackgroundImage[0]?.url : '';
                return (
                  <NewsCard
                    customCardStyle={{ background: 'transparent' }}
                    key={date}
                    imageLink={image}
                    date={date}
                    title={node.title}
                    slug={node.slug}
                  />
                );
              })}
            </Width80Box>
          </>
        );
      }}
    />
  );
};
