import React from 'react';
import { Box, Typography, Theme, Grid } from '@mui/material';
import { Absolute } from '../components/primitives/absolute';
import { CraftGql_Body_VideoGraphicText_BlockType } from '../../graphql-types';
import { CurveWithArrow } from '../assets/icons';
import { Width80Box } from '../components/primitives/Width80Box';
import { LLBox } from '../components/primitives/ll-box';
import ReactPlayer from 'react-player';
import VideoPlayIcon from '../components/video-play-icon';

export const SingleVideoSection = (props: {
  theme: Theme;
  item: CraftGql_Body_VideoGraphicText_BlockType;
}) => {
  const { item, theme } = props;
  const [factor, setFactor] = React.useState<number>(3);
  const agentVideoURL =
    item.videoUrl || 'https://player.vimeo.com/video/403142508?title=0&byline=0&portrait=0';

  const handleFactor = () => {
    if (window.innerWidth <= 1440 && window.innerWidth > 900) setFactor(3.5);
    else if (window.innerWidth <= 900) setFactor(5);
    else setFactor(3);
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleFactor);
    return () => removeEventListener('resize', handleFactor);
  }, []);

  return (
    <>
      <Box paddingX="20px" position="relative" paddingY={'45px'}>
        <Absolute top={{ xs: -25, md: -50, lg: -80 }} sx={{ left: 'calc(50% - 45px)', zIndex: 1 }}>
          <CurveWithArrow
            color={theme.palette.primary.main}
            height={factor != 5 ? 182 : 90}
            width={90}
          />
        </Absolute>
        <Width80Box display="flex" justifyContent="center">
          <LLBox mx="auto" overflow="hidden">
            <ReactPlayer
              controls
              url={agentVideoURL}
              // we can pass here thumbnail url here
              light
              playIcon={<VideoPlayIcon />}
              height={`${factor * 9}vw`}
              width={`${factor * 16}vw`}
            />
          </LLBox>
        </Width80Box>
      </Box>
    </>
  );
};
