import { useInput } from '@mui/core';
import { styled } from '@mui/material';
import * as React from 'react';
import { DARK_TEXT_COLOR } from '../../colors';

const StyledInputElement = styled('input')(`
  font-size: 16px;
  outline: none;
  font-weight: 400;
  border-radius: 28.7px;
  background-color: #d4daea;
  border: none;
  padding: 18px 28px;
  color: ${DARK_TEXT_COLOR};
  transition: all 300ms ease;

  &:hover {
  }

  &:focus {
    outline: none;
  }

   @media (max-width: 900px) {
    min-width: 60%;
  }
`);

export const CustomInput = React.forwardRef(function CustomInput(
  props: React.InputHTMLAttributes<HTMLInputElement>,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  const { getRootProps, getInputProps } = useInput(props, ref);

  return <StyledInputElement {...props} {...getInputProps()} />;
});
