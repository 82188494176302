import { Box, Theme, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { graphql, Link, StaticQuery } from 'gatsby';
import React from 'react';
import {
  CraftGql_Body_PropertyMatches_BlockType,
  Db_Property,
  PropertyMatchesQuery
} from '../../graphql-types';
import { IconMarker } from '../assets/icons/contact-us/marker';
import { IconHeart } from '../assets/icons/heart';
import { IconStar } from '../assets/icons/star';
import { CAPTIONS_TEXT_COLOR } from '../colors';
import { PropertyCard } from '../components';
import { Absolute } from '../components/primitives/absolute';
import { Width80Box } from '../components/primitives/Width80Box';
import { linkStyle } from '../constants';
import { formatParseAddress } from '../utils/formatters';

export default function LatestPropertyMatches(props: {
  item: CraftGql_Body_PropertyMatches_BlockType;
  isMobile: boolean;
  theme: Theme;
}) {
  const { item, theme } = props;
  /************************ STYLE **************************/

  const sxProps: { [propsName: string]: SxProps } = {
    latestPropertyContainer: {
      [theme.breakpoints.down(1025)]: {
        paddingX: '20px'
      }
    },
    textSubTitle: {
      mx: 'auto',
      marginTop: '12px',
      textAlign: 'center',
      marginBottom: '48px',
      maxWidth: '530px',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        marginBottom: '24px',
        paddingX: '20px'
      }
    },
    propertyCardsContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat( auto-fit, minmax(250px,1fr) )',
      gridGap: '30px',
      marginY: '50px',
      mx: 'auto',
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        gridGap: '0px',
        flexWrap: 'nowrap',
        justifyContent: 'left',
        marginY: '20px',
        overflowX: 'scroll',
        paddingBlock: '25px'
      }
    },
    cardContainerWithSvg: {
      position: 'relative',
      [theme.breakpoints.down('md')]: {
        marginRight: '16px'
      }
    },
    decorativeSvg: { height: 50, maxWidth: '12%', top: '-7%', left: '9%' }
  };

  /************************ END STYLE **************************/

  const decorativeSvgs = [
    <IconHeart height="100%" />,
    <IconStar height="100%" />,
    <IconMarker height="100%" />
  ];

  const query = graphql`
    query PropertyMatches {
      allProperties(LIMIT: 8, price_ne: null, photoURLs_ne: []) {
        Properties {
          suburb
          price
          photoURLs
          created
          bathrooms
          bedrooms
          carports
          _id
          address
          propertyType
          propertySubtype
        }
      }
    }
  `;

  const CardWithSvg = (property: Db_Property, index) => {
    const { bathrooms, bedrooms, carports, address, photoURLs, price } = property;
    return (
      <Box sx={sxProps.cardContainerWithSvg}>
        <Absolute sx={sxProps.decorativeSvg}>
          {index % 3 === 0 && decorativeSvgs[index < 7 ? index / 3 : (index / 3) % 3]}
        </Absolute>
        <PropertyCard
          imageLink={photoURLs[0]}
          address={formatParseAddress(property)}
          numberOfBathrooms={bathrooms}
          numberOfBedrooms={bedrooms}
          numberOfCarports={carports}
          price={price}
        />
      </Box>
    );
  };

  return (
    <StaticQuery
      query={query}
      render={(data: PropertyMatchesQuery) => {
        const properties = data.allProperties.Properties;
        console.log({ data });
        return (
          <Width80Box sx={sxProps.latestPropertyContainer}>
            <Typography color="primary.main" fontFamily="Tropiline" textAlign="center" variant="h3">
              {item.textTitle}
            </Typography>

            <Typography
              color={CAPTIONS_TEXT_COLOR}
              component="div"
              variant="body1"
              sx={sxProps.textSubTitle}
            >
              {item.textSubtitle}
            </Typography>
            <Box sx={sxProps.propertyCardsContainer}>
              {properties.map((property, i) => (
                <Link
                  to={`https://buyer.listingloop.com.au/buyer/#/property-preview/${property._id}`}
                  key={property._id}
                  style={linkStyle}
                >
                  {CardWithSvg(property as Db_Property, i)}
                </Link>
              ))}
            </Box>
          </Width80Box>
        );
      }}
    />
  );
}
