import { Box, Grid, Theme, Typography } from '@mui/material';
import React from 'react';
import ReactPlayer from 'react-player';
import AgencyLogo from './agency-logo';
import { LLBox } from './primitives/ll-box';
import VideoPlayIcon from './video-play-icon';

export interface VideoCardProps {
  name: string;
  agencyName: string;
  videoURL: string;
  agencyColor: string;
  agencyLogo: string;
  theme: Theme;
}

export const VideoCard = (props: VideoCardProps) => {
  const { name, agencyName, videoURL, agencyColor, agencyLogo, theme } = props;

  return (
    <Box>
      <LLBox mx="auto" width="100%" paddingX="20px" sx={{ paddingTop: '56.25%' }} id="buyer-video">
        <ReactPlayer
          className="react-player"
          controls
          url={videoURL}
          light
          playIcon={<VideoPlayIcon />}
          width="100%"
          height="100%"
        />
      </LLBox>
      <Grid container mt="30px">
        <Grid item xs={6}>
          <Box>
            <Typography variant="h5" fontFamily="Tropiline" color="primary.main">
              {name}
            </Typography>
            <Typography fontSize="14px" color="white">
              {agencyName}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} display="flex" justifyContent="flex-end" alignItems="center">
          <AgencyLogo
            agencyColor={agencyColor}
            agencyLogo={agencyLogo}
            containerStyle={{ height: '35px', width: '80%', margin: 'unset' }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
