import { Box, Theme } from '@mui/material';
import React from 'react';
import { CraftGql_Body_CtaButton_BlockType } from '../../graphql-types';
import { PrimaryButton } from '../components/primitives/primary-button';
import { Width80Box } from '../components/primitives/Width80Box';

const CtaButton = (props: {
  isMobile: boolean;
  item: CraftGql_Body_CtaButton_BlockType;
  theme: Theme;
}) => {
  const { isMobile, item, theme } = props;
  return (
    <Width80Box
      sx={{
        [theme.breakpoints.down(1025)]: {
          paddingX: '20px'
        }
      }}
    >
      <PrimaryButton
        sx={{
          display: 'block',
          mx: 'auto',
          textTransform: 'none',
          whiteSpace: 'nowrap',
          [theme.breakpoints.down(500)]: {
            width: '100%'
          }
        }}
        variant="contained"
      >
        {item.buttonTitle}
      </PrimaryButton>
    </Width80Box>
  );
};
export default CtaButton;
