import React from 'react';
import { IconSvg } from './icon-props';

export const IconVideoPlay = (props: React.CSSProperties) => {
  return (
    <IconSvg
      width={props.width || '118.899'}
      height={props.height || '118.899'}
      viewBox="0 0 118.899 118.899"
      fill={props.fill}
      style={{ ...props }}
    >
      <g id="Group_646" data-name="Group 646" transform="translate(-717.618 -1587.681)">
        <circle
          id="Ellipse_42"
          data-name="Ellipse 42"
          cx="59.449"
          cy="59.449"
          r="59.449"
          transform="translate(717.618 1587.681)"
          fill="#00a6dd"
        />
        <path
          id="Path_437"
          data-name="Path 437"
          d="M8204.684,1648.26v-16.7l14.467,8.353,14.467,8.352-14.467,8.353-14.467,8.353Z"
          transform="translate(-7438.488)"
          fill="#fff"
        />
      </g>
    </IconSvg>
  );
};
