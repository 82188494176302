import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Button, CircularProgress, Theme, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { CraftGql_Body_NewsLetter_BlockType } from '../../graphql-types';
import { IconEnvelope } from '../assets/icons/about-us/envelope';
import { DARK_TEXT_COLOR } from '../colors';
import { CustomInput } from '../components/inputs/text-field';
import { FlexBox } from '../components/primitives/flex';
import { Width80Box } from '../components/primitives/Width80Box';
import { onReadyCaptcha } from '../services/verify-captcha';
import { subscribeNewsLetter } from '../services/zapier-services';
import { loadRecaptchaScript, removeRecaptchaScript } from '../utils/script-loader';
import { validateEmail } from '../utils/validators';

export const NewsLetterSection = (props: {
  theme: Theme;
  item: CraftGql_Body_NewsLetter_BlockType;
}) => {
  const { item, theme } = props;
  /************************ STYLE **************************/
  const sxProps: { [className: string]: SxProps } = {
    wrapper: {
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column'
      }
    },
    leftSection: {
      width: '100%',
      paddingTop: '25px'
    },
    textBlock: {
      maxWidth: '360px',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        maxWidth: '100%'
      }
    },
    subtitle: { paddingTop: '30px', lineHeight: '26px' },
    formContainer: {
      paddingTop: '50px',
      maxWidth: '520px',
      minHeight: '164px',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        maxWidth: '100%'
      }
    },
    newsButton: {
      color: 'white',
      textTransform: 'capitalize',
      borderRadius: '0 50px 50px 0',
      padding: '15px 35px',
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none'
      }
    },
    rightSection: {
      flex: '45%',
      flexGrow: 1,
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
        marginTop: '50px'
      }
    }
  };
  /************************ END STYLE **************************/

  const newsData = item.newsLetterSection[0];
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [submittedStatus, setSubmittedStatus] = useState<'pending' | 'success' | 'fail'>('pending');
  const [email, setEmail] = useState('');
  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    setEmail(event.currentTarget.value);
    if (validateEmail(email)) {
      setError(false);
    }
  };

  const submitForm = (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (validateEmail(email)) {
      setError(false);
      if (submittedStatus === 'success') return;
      setIsSubmitting(true);
      onReadyCaptcha(
        async () => {
          let response = await subscribeNewsLetter(email);
          if (response.status === 'success') {
            setSubmittedStatus('success');
            setEmail('');
          } else setSubmittedStatus('fail');

          setIsSubmitting(false);
        },
        ({ error }) => {
          console.log({ error });
          setIsSubmitting(false);
        }
      );
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    const script = loadRecaptchaScript();

    return () => {
      removeRecaptchaScript(script);
    };
  }, []);

  return (
    <Box paddingY={{ xs: '40px', md: '90px', lg: '150px' }} px="20px">
      <Width80Box>
        <FlexBox sx={sxProps.wrapper}>
          <FlexBox flex="45%" flexGrow={1}>
            <Box sx={sxProps.leftSection}>
              <Box sx={sxProps.textBlock}>
                <Typography color={DARK_TEXT_COLOR} variant="h3" fontWeight={600}>
                  {newsData.newsTitle ? (
                    newsData.newsTitle
                  ) : (
                    <>The latest listings direct to your inbox</>
                  )}
                </Typography>
                <Typography color={DARK_TEXT_COLOR} sx={sxProps.subtitle}>
                  {newsData.newsSubtitle ? (
                    newsData.newsSubtitle
                  ) : (
                    <>
                      Discover pre-market and off-market properties you didn't know were for sale.
                      Listing
                    </>
                  )}
                </Typography>
              </Box>
              <Box sx={sxProps.formContainer}>
                <form onSubmit={submitForm}>
                  <FlexBox>
                    <CustomInput
                      placeholder="Email Address"
                      style={{
                        borderTopRightRadius: '0px',
                        borderBottomRightRadius: '0px',
                        border: error ? '2px solid tomato' : 'none',
                        flex: 1
                      }}
                      value={email}
                      onChange={handleChange}
                    />

                    <Button
                      sx={sxProps.newsButton}
                      variant="contained"
                      type="submit"
                      // color={submittedStatus === 'success' ? 'success' : 'inherit'}
                      disabled={isSubmitting || submittedStatus === 'success'}
                    >
                      {isSubmitting ? (
                        <Typography color="white">
                          <CircularProgress color="inherit" size={18} />
                        </Typography>
                      ) : submittedStatus === 'success' ? (
                        'Subscribed'
                      ) : (
                        newsData.newsButtonText
                      )}
                    </Button>
                  </FlexBox>

                  <Box p="15px 20px">
                    {submittedStatus === 'pending' ? null : submittedStatus === 'success' ? (
                      <FlexBox width="fit-content">
                        <CheckCircleIcon color="success" sx={{ mr: '10px' }} />
                        <Typography>Thanks for subscribing our newsletter.</Typography>
                      </FlexBox>
                    ) : (
                      <Typography color="red">Something went wrong</Typography>
                    )}
                  </Box>
                </form>
              </Box>
            </Box>
          </FlexBox>
          <Box flex="10%"></Box>
          <Box sx={sxProps.rightSection}>
            {newsData.newsImage[0]?.url ? (
              <img
                src={newsData.newsImage[0]?.url}
                height="auto"
                width="100%"
                style={{
                  objectFit: 'cover',
                  objectPosition: 'center',
                  maxWidth: '433px',
                  width: '100%'
                }}
              />
            ) : (
              <IconEnvelope height="auto" width="100%" />
            )}
          </Box>
        </FlexBox>
      </Width80Box>
    </Box>
  );
};
