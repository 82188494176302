import { Box, Button, Grid, Theme, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { Link } from 'gatsby';
import React, { useState } from 'react';
import { CraftGql_Body_AgentsBuyersDotPointsSection_BlockType } from '../../graphql-types';
import { IconMap } from '../assets/icons/icon-map';
import { TABS_COLOR } from '../colors';
import { Width80Box } from '../components/primitives/Width80Box';
import { imageStyle } from '../constants';
import { UserType } from '../interfaces/user';

export const AgentsBuyersDotPointsSection = (props: {
  theme: Theme;
  item: CraftGql_Body_AgentsBuyersDotPointsSection_BlockType;
  isMobile: boolean;
}) => {
  const [activeTab, setActiveTab] = useState<UserType>(UserType.buyer);
  const { item, isMobile, theme } = props;

  // style
  const ulPoints: SxProps = {
    [theme.breakpoints.down('md')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      width: 'fit-content'
    }
  };

  const homePagePoints = {
    color: theme.palette.secondary.main,
    lineHeight: '26px',
    listStyle: 'none',
    marginBottom: '15px'
  };
  const tabsSxProps = (tab: UserType): SxProps => ({
    border: `2px solid transparent`,
    borderColor: activeTab === tab ? theme.palette.primary.main : 'transparent',
    borderRadius: '50px',
    mr: '10px',
    color: activeTab === tab ? theme.palette.secondary.main : TABS_COLOR,
    textTransform: 'none',
    padding: isMobile ? '10px 20px' : '15px 35px',
    fontSize: isMobile ? '13px' : 'inherit'
  });

  const {
    agentsDotPoints,
    buyersDotPoints,
    listingLoopBuyerPointsHeading,
    listingLoopBuyersPointsLink,
    listingLoopBuyersPointsLinkLabel,
    listingLoopAgentPointsLink,
    listingLoopAgentPointsLinkLabel,
    listingLoopAgentPointsHeading,
    imageElement
  } = item;

  const defaultHeading = 'Australia’s leading place for off-market properties';
  const imageUrl = imageElement ? imageElement[0]?.url : null;

  const handleActiveTab = (tab: UserType) => setActiveTab(tab);

  const renderData =
    activeTab === UserType.agent
      ? {
          points: agentsDotPoints,
          heading: listingLoopAgentPointsHeading,
          link: listingLoopAgentPointsLink,
          linkLabel: listingLoopAgentPointsLinkLabel
        }
      : {
          points: buyersDotPoints,
          heading: listingLoopBuyerPointsHeading,
          link: listingLoopBuyersPointsLink,
          linkLabel: listingLoopBuyersPointsLinkLabel
        };
  const { points, heading, link, linkLabel } = renderData;

  return (
    <Width80Box
      sx={{
        [theme.breakpoints.down(1025)]: {
          paddingX: '20px'
        }
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="center">
        <Button
          onClick={() => handleActiveTab(UserType.buyer)}
          sx={tabsSxProps(UserType.buyer)}
          variant="text"
        >
          {!isMobile ? 'Listing Loop for buyers' : 'For buyers'}
        </Button>
        <Button
          onClick={() => handleActiveTab(UserType.agent)}
          sx={tabsSxProps(UserType.agent)}
          variant="text"
        >
          {!isMobile ? 'Listing Loop for agents' : 'For agents'}
        </Button>
      </Box>
      <Grid
        container
        columnSpacing={{ xs: 0, md: 2 }}
        alignItems={'center'}
        justifyContent="center"
        sx={{ flexGrow: 1, marginTop: '44px' }}
      >
        <Grid item xs={7} md={5} py={{ xs: 0, md: '60px' }}>
          {imageUrl ? (
            <img
              src={imageUrl}
              alt="Points section image"
              style={{ ...imageStyle, maxWidth: '433px' }}
            />
          ) : (
            <IconMap height="auto" style={{ maxWidth: '433px' }} width="100%" />
          )}
        </Grid>
        <Grid item xs={12} md={5} mt={'50px'}>
          <Typography
            color="secondary"
            fontWeight={500}
            mb="30px"
            mx="12px"
            sx={{
              textAlign: 'left',
              [theme.breakpoints.down('md')]: {
                textAlign: 'center'
              }
            }}
            variant="h3"
          >
            {heading || defaultHeading}
          </Typography>
          <Box component="ul" sx={ulPoints}>
            {points.map((point, i) => (
              <li key={point.col1} style={{ display: 'flex', ...homePagePoints }}>
                <span style={{ fontFamily: 'Inter', marginRight: '8px' }}>✓</span>
                <span style={{ display: 'block' }}>{point.col1}</span>
              </li>
            ))}
          </Box>
          <Typography
            color="primary.main"
            sx={{
              marginTop: '30px',
              textAlign: 'left',
              [theme.breakpoints.down('md')]: {
                textAlign: 'center'
              }
            }}
          >
            <Link
              to={link || '/'}
              style={{
                color: 'inherit',
                fontFamily: 'Rubik',
                fontSize: '16px',
                fontWeight: 500,
                textDecoration: 'none',
                textTransform: 'none'
              }}
            >
              {linkLabel || 'Curious to get in the loop?'}
              <span
                style={{
                  fontFamily: 'Inter',
                  marginLeft: '8px'
                }}
              >
                →
              </span>
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Width80Box>
  );
};
