import { Db_Property } from '../../graphql-types';

export const formatDateTimeWithMonthName = (date: number | null): string => {
  if (!date) {
    return '';
  }
  let formattedDate = new Date(date);

  return formattedDate.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
};

export interface AddressInfo {
  street: string;
  suburb: string;
  state: string;
  postcode: string;
}

function softTrim(word: string): string {
  const endsWithSpace = word && word.length > 1 && word[word.length - 1] === ' ';

  return endsWithSpace ? word.trim() + ' ' : word.trim();
}

// TODO: clean up `parseAddress`
/**
 * Parses a complete Australian address in the format:
 * "Wattamolla Avenue, Tarneit, VIC, 3030". Depending on provided data some parts
 * of the returned `Address` may be empty.
 */
export function parseAddress(address: string): AddressInfo {
  if (!address) {
    return {
      street: '',
      suburb: '',
      state: '',
      postcode: ''
    };
  }

  if (address.includes(',')) {
    const tokens = address.split(',');
    switch (tokens.length) {
      case 3:
        return {
          street: softTrim(tokens[0]),
          suburb: softTrim(tokens[1]),
          state: softTrim(tokens[2]),
          postcode: ''
        };
      case 4:
        return {
          street: softTrim(tokens[0]),
          suburb: softTrim(tokens[1]),
          state: softTrim(tokens[2]),
          postcode: softTrim(tokens[3])
        };
      case 5:
        return {
          street: softTrim(`${tokens[0]} ${tokens[1]}`),
          suburb: softTrim(tokens[2]),
          state: softTrim(tokens[3]),
          postcode: softTrim(tokens[4])
        };
      default:
        return {
          street: softTrim(tokens[0]),
          suburb: softTrim(tokens[1]),
          state: '',
          postcode: ''
        };
    }
  } else {
    return {
      street: softTrim(address),
      suburb: '',
      state: '',
      postcode: ''
    };
  }
}

const offThePlanType = 'Off the Plan';
const landTypes = ['Acreage', 'Land', 'Working Farm', 'Block of Units'];

export const formatParseAddress = (property: Db_Property): string => {
  //
  let prefix;
  let address = parseAddress(property.address);

  if (isLandType(property)) {
    prefix =
      property.propertyType === offThePlanType
        ? `Off the Plan, ${property.propertySubtype}`
        : property.propertyType;
  } else {
    prefix =
      property.propertyType === offThePlanType
        ? `${property.bedrooms} Bedroom Off the Plan, ${property.propertySubtype}`
        : `${property.bedrooms} Bedroom ${property.propertyType}`;
  }

  if (!property.suburb) {
    return prefix;
  }

  return `${prefix}, ${address.suburb}`;
};
const isLandType = (property: Db_Property) => {
  const propertyType = property.propertyType;
  const propertySubtype = property.propertySubtype;

  return propertyType === offThePlanType
    ? propertySubtype && landTypes.includes(propertySubtype)
    : landTypes.includes(propertyType);
};
